<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1600 1200"
    style="enable-background: new 0 0 1600 1200"
    xml:space="preserve"
  >
    <g id="background">
      <g id="details">
        <circle class="st0" cx="367.7" cy="292.8" r="12.8" />
        <rect x="394.9" y="315.4" class="st0" width="0" height="0" />
        <circle class="st0" cx="573.8" cy="793.6" r="9.4" />
        <circle class="st0" cx="1216.4" cy="236.2" r="13.4" />
        <circle class="st0" cx="728.5" cy="228.5" r="5.6" />
        <circle class="st1" cx="382" cy="245.4" r="9.1" />
        <circle class="st1" cx="597.1" cy="778" r="5" />
        <circle class="st1" cx="731.4" cy="199.6" r="5.9" />
        <circle class="st1" cx="1184.7" cy="205.9" r="3.4" />
        <g>
          <g>
            <path
              class="st0"
              d="M1166.6,606.1c2.6-5.2,8.7,1.5,10.5,3.5c1.8,2,4,4.2,6.8,4.7c5.3,1,7.8-3.8,12-5.6
					c5.9-2.7,7.3,7.2,11.6,9.3c2,1,3.6,0.5,5.6-0.1c2.3-0.6,4-2,6.5-1.3c3,0.8,5.1,3.3,5.5,6.4c0.2,1.9,3.2,1.9,3,0
					c-0.6-6.1-6.4-11-12.6-9.4c-2.6,0.7-5.2,2.8-7.6,0.9c-1.4-1.1-2.2-3.3-3.2-4.8c-1.4-2.1-3-4.1-5.6-4.5c-3-0.5-5.3,1.2-7.6,2.9
					c-2.4,1.8-3.9,3.4-7,2.9c-3.1-0.6-4.6-2.9-6.7-5.1c-3.3-3.6-10.8-7.2-13.8-1.3C1163.2,606.3,1165.8,607.8,1166.6,606.1
					L1166.6,606.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M1189.7,627.3c4.6-0.7,9.4,0.6,12.9,3.6c2.5,2.1,3.3,4.7,5,7.3c3.5,5.3,7.9,0.7,12.5,0.2
					c7.1-0.8,10.8,7.5,17.6,8.6c4,0.6,7.6-1.1,9.1-4.9c0.7-1.8-2.2-2.6-2.9-0.8c-3.4,8.5-14.2-3.2-18.3-5.1
					c-1.8-0.8-3.8-1.4-5.7-1.2c-2.6,0.2-7.3,4-9.7,1.8c-1.4-1.3-2-4.2-3.4-5.8c-1.3-1.6-2.9-3-4.7-4c-4.1-2.5-8.6-3.2-13.3-2.5
					C1187,624.7,1187.8,627.6,1189.7,627.3L1189.7,627.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M290.6,459.9c-1.1-6.1-1.3-12.1,5.7-14.5c2.8-1,5.8-1.5,8.1-3.5c4.2-3.6,3.9-9.3,3.6-14.4
					c-0.2-3.7-1.1-9.4,4.5-7.9c1.8,0.5,3.7,1.2,5.5,0.3c1.4-0.7,2-1.9,2.4-3.3c0.9-3,0-5.4,2.6-7.9c2.1-2.1,4.7-2.7,7.6-2.3
					c1.9,0.3,1.9-2.7,0-3c-3.2-0.4-6.2,0.3-8.7,2.3c-3.2,2.5-3.3,5.1-4.3,8.7c-1.5,5.1-5.2,0.9-8.9,1.6c-2.3,0.4-3.3,2-3.8,4.1
					c-0.9,4,0.4,8.3,0.3,12.3c-0.3,7.5-5.3,8.2-11,10.5c-7.8,3.1-7.7,10.5-6.4,17.7C288,462.6,290.9,461.8,290.6,459.9L290.6,459.9z
					"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M324,440.1c-6-7.7,0.9-15.3,9.2-14.6c2.5,0.2,4.6,0,5.9-2.3c1.8-3,1.2-7.1,2.6-10.3
					c2.3-5.5,6.8-9.4,12.4-11.2c1.8-0.6,1-3.5-0.8-2.9c-5,1.6-9.3,4.6-12.3,9c-1.7,2.5-2.9,5.3-3.4,8.3c-0.2,1-0.2,2.9-0.5,3.8
					c-0.8,2.6-3.3,2.3-5.6,2.4c-10.6,0.6-16.5,11.1-9.6,20C323,443.8,325.1,441.7,324,440.1L324,440.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M607.1,190.5c-1.7-5.6,3.8-11.4,9.4-8.4c6.1,3.2,4,12.1-2.7,12.8c-1.9,0.2-1.9,3.2,0,3
					c9-0.9,13-12.6,4.9-17.9c-7.8-5.1-17.2,2.8-14.5,11.4C604.8,193.1,607.7,192.3,607.1,190.5L607.1,190.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M938.6,758.4c-4.1,4.9,1.5,9.1,5.8,10.8c3.3,1.3,5.5,3,6,6.7c0.2,1.7,0.2,3.4,0.6,5
					c0.4,1.3,0.9,2.5,1.8,3.4c1.4,1.5,6.8,2.9,6,5.7c-0.5,1.9,2.4,2.7,2.9,0.8c0.7-2.5-0.1-4.3-2.2-6c-1.4-1.1-3.4-1.4-4.6-2.7
					c-1.9-2-1.3-5.2-1.7-7.6c-0.4-2.3-1.1-4.3-2.9-5.9c-1.9-1.6-12.9-4.2-9.6-8.2C942,759,939.9,756.9,938.6,758.4L938.6,758.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M951.8,748.4c-0.9,2.2-0.1,4.3,1.7,5.8c1.7,1.4,4.4,1.5,5.9,3.1c1.6,1.7,1.4,4,1.8,6.2
					c0.5,2.8,1.5,4.9,4.1,6.3c1.9,1,4,1.3,5.8,2.4c3.3,2.1,5.2,5.7,5.7,9.5c0.2,1.9,3.2,1.9,3,0c-0.8-7.1-4.9-11.5-11.4-14
					c-5.1-1.9-3.6-6.2-5.3-10.2c-0.5-1.3-1.2-2.2-2.3-3c-1.7-1.2-7.4-2.2-6.1-5.4C955.4,747.4,952.5,746.6,951.8,748.4L951.8,748.4z
					"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M986.7,146.6c-3.1-4.1,3.1-7,6.2-7.4c2.3-0.3,4.7-0.1,7-0.5c3.2-0.6,6.1-2.1,8.3-4.6c2-2.3,2.3-4.6,3.2-7.4
					c0.5-1.7,1.8-4.5,3.7-5.3c0.7-0.3,2.4,0.2,3.3,0.2c1.7,0.1,3.2,0.1,4.9-0.3c1.9-0.4,1.1-3.3-0.8-2.9c-4,0.8-9.5-1.7-12.5,2
					c-1.1,1.4-1.2,3.1-1.4,4.8c-0.6,4.6-2.8,8.6-7.5,10c-5.2,1.6-11.3-0.5-15.6,3.8c-2.5,2.5-3.4,6-1.3,8.9
					C985.3,149.6,987.9,148.1,986.7,146.6L986.7,146.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M1017,145.4c0.1-5,2.6-9.6,7-12.2c3.3-2,7-1.8,10.8-1.8c5.1,0.1,10,0,13.3-4.4c1.1-1.6-1.5-3.1-2.6-1.5
					c-4.4,5.9-15.5,1.5-21.8,4.5c-5.9,2.8-9.5,8.9-9.6,15.4C1014,147.3,1017,147.3,1017,145.4L1017,145.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M608,483.7c0.9-0.2,1.8-0.5,2.8-0.7c0.4,0.9,0.8,1.9,1.2,2.8c0.2,1.3-0.3,2.8-0.5,4
					c-0.2,1.5-0.5,2.8-0.4,4.3c0.2,2.2,1.1,4.6,3.1,5.7c3,1.6,6.4-0.7,9.5,0.9c4.1,2,6.3,6.9,8.1,10.9c0.8,1.8,3.4,0.2,2.6-1.5
					c-1.9-4.3-4.4-9.1-8.6-11.6c-2.1-1.2-3.7-1.2-6-1.4c-2.4-0.2-3.2,2.6-5.6-2.3c-0.7-1.6,0.2-4,0.4-5.6c0.5-3.5,0.7-7.7-3.2-9.2
					c-2.6-1-5.8-0.1-6.3,2.9C604.8,484.8,607.7,485.6,608,483.7L608,483.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M628.5,480c-2.9,2-1.7,5.2,0.3,7.6c2.2,2.6,4.7,4.7,6.7,7.6c2.3,3.4,3.9,7.1,5.1,10.9
					c0.6,1.8,3.5,1.1,2.9-0.8c-1.3-4.1-3-8.1-5.4-11.7c-1.1-1.6-9.3-10.2-8-11.1C631.6,481.5,630.1,478.9,628.5,480L628.5,480z"
            />
          </g>
        </g>
      </g>
      <g id="papers">
        <g>
          <g>
            <path
              class="st2"
              d="M484.1,251.4c3.1,37.1,6,74.8-1.6,111.6c-0.4,1.9,2.5,2.7,2.9,0.8c7.7-37.1,4.8-75,1.7-112.4
					C486.9,249.5,483.9,249.5,484.1,251.4L484.1,251.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M485.8,252.4c18.4,0.8,36.9,0.8,55.3-0.1c15.9-0.8,33.2-0.8,48-7.2c1.8-0.8,0.2-3.4-1.5-2.6
					c-15.4,6.7-33.9,6.3-50.4,7c-17.1,0.7-34.3,0.7-51.4-0.1C483.9,249.3,483.9,252.3,485.8,252.4L485.8,252.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M586.6,244.7c9.6,36.6,11.9,74.8,6.6,112.3c-0.3,1.9,2.6,2.7,2.9,0.8c5.3-38.1,3.1-76.7-6.6-113.9
					C589.1,242,586.2,242.8,586.6,244.7L586.6,244.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M483.3,364.5c19.8,4.4,40.2,4.8,60.4,3.4c10.2-0.7,20.5-1.9,30.7-3.1c7.3-0.9,14.9-1.3,20.9-6
					c1.5-1.2-0.6-3.3-2.1-2.1c-6.8,5.3-17.4,5-25.5,5.9c-9.2,1.1-18.5,2-27.8,2.6c-18.7,1.1-37.4,0.6-55.7-3.5
					C482.2,361.2,481.4,364.1,483.3,364.5L483.3,364.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M491.3,355.7c2.4,0.1,4.7-0.7,7.1-0.9c2.9-0.2,5.6,0.1,8.4,0.8c1.9,0.5,2.7-2.4,0.8-2.9
					c-2.6-0.7-5.3-1.1-8-1c-2.8,0.1-5.5,1.1-8.3,1C489.4,352.6,489.4,355.6,491.3,355.7L491.3,355.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M493.1,348.7c4.9,0.1,9.9,0.1,14.8,0.2c1.9,0,1.9-3,0-3c-4.9-0.1-9.9-0.1-14.8-0.2
					C491.2,345.6,491.2,348.6,493.1,348.7L493.1,348.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M494.1,341.2c4.4,0.2,8.7,0.4,13.1,0.6c1.9,0.1,1.9-2.9,0-3c-4.4-0.2-8.7-0.4-13.1-0.6
					C492.2,338.1,492.2,341.1,494.1,341.2L494.1,341.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M495.5,322.2c4.9-0.6,9.8-0.9,14.8-0.8c1.9,0,1.9-3,0-3c-4.9-0.1-9.9,0.2-14.8,0.8
					C493.6,319.5,493.6,322.5,495.5,322.2L495.5,322.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M496.5,314.3c4.7,0.1,9.4,0.2,14.1,0.3c1.9,0,1.9-3,0-3c-4.7-0.1-9.4-0.2-14.1-0.3
					C494.6,311.3,494.6,314.3,496.5,314.3L496.5,314.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M495.5,307.5c4.3,0.3,8.5,0.2,12.7-0.2c1.9-0.2,1.9-3.2,0-3c-4.2,0.5-8.5,0.5-12.7,0.2
					C493.6,304.3,493.6,307.3,495.5,307.5L495.5,307.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M496.2,288.3c5.2-1,10.4-1.5,15.8-1.4c1.9,0,1.9-3,0-3c-5.6-0.1-11.1,0.4-16.6,1.5
					C493.6,285.8,494.4,288.7,496.2,288.3L496.2,288.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M495.9,281c5.6,0.1,11.2,0.1,16.9-0.2c1.9-0.1,1.9-3.1,0-3c-5.6,0.3-11.2,0.4-16.9,0.2
					C493.9,277.9,493.9,280.9,495.9,281L495.9,281z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M493.8,273.2c6.2,0.2,12.4-0.1,18.6-0.7c1.9-0.2,1.9-3.2,0-3c-6.2,0.6-12.4,0.9-18.6,0.7
					C491.9,270.2,491.9,273.2,493.8,273.2L493.8,273.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M527.2,270.4c15.5-2.3,31.1-3.4,46.7-3.2c1.9,0,1.9-3,0-3c-15.9-0.2-31.8,0.9-47.5,3.3
					C524.5,267.8,525.3,270.7,527.2,270.4L527.2,270.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M530.3,276c15.4-1.3,30.9-2.1,46.4-2.6c1.9-0.1,1.9-3.1,0-3c-15.5,0.4-31,1.3-46.4,2.6
					C528.3,273.2,528.3,276.2,530.3,276L530.3,276z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M529.1,282.7c16-0.8,32.1,1.1,48.1-0.6c1.9-0.2,1.9-3.2,0-3c-16,1.8-32.1-0.2-48.1,0.6
					C527.2,279.8,527.2,282.8,529.1,282.7L529.1,282.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M519.6,307.5c7,0,14-0.1,21-0.1c1.9,0,1.9-3,0-3c-7,0-14,0.1-21,0.1C517.6,304.5,517.6,307.5,519.6,307.5
					L519.6,307.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M520.3,315.1c7.5-0.2,15.1-0.4,22.6-0.5c2.1,0,22.1-1.1,22.1-0.9c0.6,1.8,3.5,1.1,2.9-0.8
					c-0.6-2.1-2.4-1.8-4.3-1.8c-4.6,0-9.2,0.2-13.8,0.3c-9.8,0.2-19.7,0.5-29.5,0.7C518.3,312.1,518.3,315.1,520.3,315.1
					L520.3,315.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M520.3,322.6c6,0.3,11.9,0.3,17.9,0.2c1.9-0.1,1.9-3.1,0-3c-6,0.2-11.9,0.1-17.9-0.2
					C518.3,319.5,518.3,322.5,520.3,322.6L520.3,322.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M516.8,339.8c20-1.3,40.1-1.8,60.2-1.5c1.9,0,1.9-3,0-3c-20.1-0.3-40.1,0.2-60.2,1.5
					C514.9,336.9,514.9,339.9,516.8,339.8L516.8,339.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M519.9,346.4c5-0.1,10.1-0.2,15.1-0.3c1.9,0,1.9-3,0-3c-5,0.1-10.1,0.2-15.1,0.3
					C518,343.4,518,346.4,519.9,346.4L519.9,346.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M547.1,346.3c8.1-0.8,16.3-1.5,24.4-2c1.9-0.1,1.9-3.1,0-3c-8.2,0.5-16.3,1.2-24.4,2
					C545.2,343.5,545.1,346.5,547.1,346.3L547.1,346.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M518.5,356c16.4-0.2,32.8-0.7,49.2-1.3c1.9-0.1,1.9-3.1,0-3c-16.4,0.7-32.8,1.1-49.2,1.3
					C516.6,353,516.6,356,518.5,356L518.5,356z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M598.8,297.3c25.9-4.8,52.2-7.1,78.6-6.9c-0.5-0.4-1-0.7-1.4-1.1c15.4,49.1,22.8,100.5,21.9,152
					c0,1.9,3,1.9,3,0c0.9-51.7-6.5-103.4-22-152.8c-0.2-0.6-0.8-1.1-1.4-1.1c-26.6-0.2-53.2,2.1-79.4,7
					C596.1,294.7,596.9,297.6,598.8,297.3L598.8,297.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M545.4,366.7c1.8,28.1-2.1,56.1-11.6,82.5c-0.6,1.8,2.2,2.6,2.9,0.8c9.5-26.7,13.5-55,11.7-83.3
					C548.3,364.8,545.3,364.7,545.4,366.7L545.4,366.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M534.3,451.4c25.2,10.7,52.8,9.6,79.5,7.1c29.1-2.8,57.7-7.5,85.7-15.8c1.8-0.5,1.1-3.4-0.8-2.9
					c-27.2,8-54.9,12.8-83.2,15.5c-26.6,2.6-54.5,4.2-79.7-6.5C534.1,448,532.6,450.6,534.3,451.4L534.3,451.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M569.5,375c-5.4,2.9-10.6,6.2-15.7,9.7c-1,0.7-1,2.5,0.4,2.7c4.6,1,9.2,1.9,13.8,2.9
					c1.9,0.4,2.7-2.5,0.8-2.9c-4.6-1-9.2-1.9-13.8-2.9c0.1,0.9,0.2,1.8,0.4,2.7c5-3.5,10.3-6.8,15.7-9.7
					C572.7,376.7,571.2,374.1,569.5,375L569.5,375z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M592.8,365.8c-6.6,10.7-13.2,21.4-19.8,32c-1,1.6,1.6,3.2,2.6,1.5c6.6-10.7,13.2-21.4,19.8-32
					C596.4,365.7,593.8,364.2,592.8,365.8L592.8,365.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M597.4,388.5c5.9-0.9,11.9-1.8,17.8-2.7c1.9-0.3,1.1-3.2-0.8-2.9c-5.9,0.9-11.9,1.8-17.8,2.7
					C594.7,385.9,595.5,388.8,597.4,388.5L597.4,388.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M627.2,372.4c-0.1,0-0.2,0-0.3,0c-0.7-0.1-1.3,0.1-1.7,0.7c-0.1,0.1-0.1,0.2-0.2,0.3
					c-0.3,0.4-0.3,1.1,0,1.5c2.3,3.4,7.7,4.2,11.4,5c0.7,0.2,1.4,0.3,2.1,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.7-3.2,3.1-3.8,3.6
					c-2.2,2.1-4.4,4.2-6.6,6.3c-1.4,1.3,0.7,3.5,2.1,2.1c2.7-2.5,5.3-5.1,8-7.6c1.4-1.4,4.2-3.2,3.6-5.5c-0.5-1.9-2.6-2.1-4.3-2.6
					c-2.7-0.7-7.9-1.1-9.6-3.7c0,0.5,0,1,0,1.5c0.1-0.1,0.1-0.2,0.2-0.3c-0.6,0.2-1.1,0.5-1.7,0.7c0.1,0,0.2,0,0.3,0
					C628.3,375.6,629.1,372.7,627.2,372.4L627.2,372.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M606.7,307.4c5.2-0.9,10.4-1.2,15.7-1c1.9,0.1,1.9-2.9,0-3c-5.6-0.2-11.1,0.2-16.5,1.2
					C604,304.9,604.8,307.7,606.7,307.4L606.7,307.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M605.9,315c7.1-0.7,14.2-1.3,21.3-1.8c1.9-0.1,1.9-3.1,0-3c-7.1,0.5-14.2,1.1-21.3,1.8
					C604,312.2,604,315.2,605.9,315L605.9,315z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M609.1,326.3c0,0,0,0.1,0,0.1c0.5-0.4,1-0.7,1.4-1.1c-0.1,0-0.1,0-0.2,0c0.4,0.2,0.9,0.5,1.3,0.7
					c0-0.1-0.1-0.1-0.1-0.2c-0.1,0.6-0.2,1.2-0.2,1.8c-0.7,0.2-0.6,0.3,0.4,0.2c0.6,0,1.2,0,1.7,0c1,0,2-0.1,3-0.1
					c2-0.2,4-0.4,6-0.8c1.9-0.3,1.1-3.2-0.8-2.9c-2.6,0.5-5.2,0.7-7.8,0.8c-1.6,0-3.5-0.5-4.7,0.7c-0.5,0.5-0.5,1.3-0.2,1.8
					c0.4,0.6,0.9,0.9,1.6,0.9c0.6,0,1.3-0.5,1.4-1.1c0,0,0-0.1,0-0.1C612.4,325.2,609.5,324.4,609.1,326.3L609.1,326.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M607,340.9c8.4-1.2,16.8-2.8,25.3-2.5c1.9,0.1,1.9-2.9,0-3c-8.8-0.3-17.5,1.4-26.1,2.6
					C604.3,338.3,605.1,341.2,607,340.9L607,340.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M639.6,304.2c9.6-1.3,19.2-2,28.8-2.3c1.9-0.1,1.9-3.1,0-3c-9.7,0.3-19.3,1-28.8,2.3
					C637.7,301.4,637.7,304.4,639.6,304.2L639.6,304.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M646.5,316.8c6-1,11.9-1.9,17.9-2.9c1.9-0.3,1.1-3.2-0.8-2.9c-6,1-11.9,1.9-17.9,2.9
					C643.8,314.2,644.6,317.1,646.5,316.8L646.5,316.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M648.5,334.3c5.1-0.5,10.3-0.7,15.5-0.7c1.9,0,1.9-3,0-3c-5.2,0-10.3,0.2-15.5,0.7
					C646.6,331.5,646.6,334.5,648.5,334.3L648.5,334.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M657.5,340c6.3-0.9,12.5-1.5,18.9-1.9c1.9-0.1,1.9-3.1,0-3c-6.6,0.4-13.1,1.1-19.7,2
					C654.8,337.4,655.6,340.2,657.5,340L657.5,340z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M558.4,416.3c1.9,0,1.9-3,0-3C556.5,413.3,556.5,416.3,558.4,416.3L558.4,416.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M555.2,413.6c2.5,0.8,4.9,0.5,7.5,0.4c3.4-0.1,6.8,0.1,10.2,0.4c1.9,0.2,1.9-2.8,0-3
					c-2.9-0.3-5.9-0.5-8.8-0.4c-2.6,0-5.5,0.6-8-0.3C554.2,410.1,553.4,413,555.2,413.6L555.2,413.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M558.8,422.6c5.5-0.5,11-0.4,16.5,0.4c1.9,0.2,1.9-2.8,0-3c-5.5-0.7-11-0.8-16.5-0.4
					C556.9,419.8,556.9,422.8,558.8,422.6L558.8,422.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M550.1,435.3c4.7,1.8,9.6,1.5,14.6,1.5c5.8,0,11.6,0,17.4,0c10.2,0,21.2,1.1,31.1-1.9
					c1.8-0.6,1.1-3.5-0.8-2.9c-9.6,3-20.4,1.8-30.3,1.8c-5.2,0-10.3,0-15.5,0c-5.3,0-10.7,0.5-15.8-1.4
					C549,431.7,548.3,434.6,550.1,435.3L550.1,435.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M601.8,421.1c3.2-0.2,6.4-0.3,9.6-0.5c3.2-0.2,6.5-0.7,9.5,0.6c1.8,0.7,2.6-2.2,0.8-2.9
					c-2.9-1.2-5.8-0.9-8.9-0.7c-3.7,0.2-7.4,0.4-11.1,0.6C599.8,418.2,599.8,421.2,601.8,421.1L601.8,421.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M589.4,448.4c16.1-0.6,32.1-1.5,48.2-2.8c1.9-0.2,1.9-3.2,0-3c-16,1.3-32.1,2.2-48.2,2.8
					C587.4,445.5,587.4,448.5,589.4,448.4L589.4,448.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M768.5,162.7c4.5,47.4,3.5,95,2.5,142.5c0,1.9,3,1.9,3,0c1.1-47.5,2-95.1-2.5-142.5
					C771.3,160.8,768.3,160.8,768.5,162.7L768.5,162.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M770.1,163.8c20.3,0.9,40.2-4.8,60.3-7c20.4-2.2,41-2.2,61.4-0.1c1.9,0.2,1.9-2.8,0-3
					c-20.4-2.1-41-2.1-61.4,0.1c-20.1,2.2-40,7.9-60.3,7C768.1,160.8,768.1,163.8,770.1,163.8L770.1,163.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M890.5,155.9c2.7,10.5,3.1,21.3,3.3,32c0.3,11.5,0.7,23.1,1.1,34.6c0.8,22.7,1.8,45.5,3,68.2
					c0.1,1.9,3.1,1.9,3,0c-1.2-23.4-2.3-46.8-3.1-70.2c-0.4-11.5-0.7-23-1-34.4c-0.3-10.4-0.8-20.9-3.4-31
					C892.9,153.2,890,154,890.5,155.9L890.5,155.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M772.4,307.4c21.5,6.2,44.6,3.9,66.5,1.7c21.1-2.1,42-6.1,61.3-15.4c1.7-0.8,0.2-3.4-1.5-2.6
					c-19.3,9.3-40.4,13.1-61.5,15.2c-21.1,2-43.3,4.2-63.9-1.8C771.3,303.9,770.5,306.8,772.4,307.4L772.4,307.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M895.7,182.8c12.2-1.9,24.6-2.5,37-1.8c6.4,0.4,12.8,1.1,19.2,2.2c2.7,0.5,5.7,0.7,8.3,1.6
					c3.7,1.3,3.5,4,4.1,7.5c4.6,24.5,7.6,49.3,9.1,74.2c0.1,1.9,3.1,1.9,3,0c-0.9-14.1-2.2-28.2-4-42.2c-0.8-6.5-1.8-13-2.9-19.5
					c-0.6-3.5-1.2-7-1.8-10.5c-0.5-3-0.7-6.8-1.9-9.5c-1.7-3.9-8.6-3.8-12.2-4.4c-6.3-1.1-12.6-1.9-19-2.3
					c-13.3-0.9-26.5-0.2-39.7,1.8C893,180.2,893.8,183.1,895.7,182.8L895.7,182.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M858.1,306.2c1.8,5,2.9,10,3.1,15.3c0.1,2.6-0.3,5.7,1.6,7.8c1.9,2.1,5.2,2.1,7.8,2.5
					c4.2,0.6,8.5,1.1,12.7,1.3c19.5,1.1,39-1.4,58.3-4c1.9-0.2,1.9-3.3,0-3c-16.5,2.2-33,4.4-49.7,4.2c-3.8,0-7.6-0.2-11.4-0.5
					c-2.5-0.2-4.9-0.5-7.4-0.8c-1.7-0.2-5.3-0.1-6.6-1.1c-3.4-2.5-2.3-8.2-2.9-11.9c-0.5-3.7-1.5-7.2-2.7-10.7
					C860.3,303.6,857.4,304.3,858.1,306.2L858.1,306.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M936.8,268.1c2.2,9.6,2.6,19.5,3.1,29.4c0.5,9.4,1,18.9,1.4,28.3c0.8,19.2,1.3,38.4,1.5,57.7
					c0,1.9,3,1.9,3,0c-0.2-19.9-0.8-39.7-1.6-59.6c-0.8-18.6-0.3-38.3-4.5-56.5C939.3,265.4,936.4,266.2,936.8,268.1L936.8,268.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M938.8,269.9c20.9-1.2,41.8-0.9,62.6,0.8c1.9,0.2,1.9-2.8,0-3c-20.8-1.8-41.7-2-62.6-0.8
					C936.9,267.1,936.9,270.1,938.8,269.9L938.8,269.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M942.5,384.1c2.5,4.3,6.6,5.9,11.4,6.5c7.2,0.9,14.5,1.2,21.7,1.7c15.4,1,30.8,1.8,46.2,1.9
					c7.5,0,15.1-0.1,22.6-0.4c3.6-0.2,7.2-0.4,10.8-0.6c1.9-0.1,5.2,0.2,6.9-1.2c1.1-0.9,1.2-2.2,1.4-3.6c0.4-3.9,0.3-7.9,0.4-11.8
					c0.3-7.9,0.5-15.8,0.8-23.7c0.1-1.9-2.9-1.9-3,0c-0.2,6.2-0.4,12.5-0.6,18.7c-0.1,3-0.2,5.9-0.3,8.9c-0.1,1.6-0.1,3.3-0.2,4.9
					c-0.1,2.8-0.4,3.8-3.4,4.6c-2.6,0.7-6.1,0.4-8.8,0.6c-3.3,0.2-6.5,0.3-9.8,0.4c-6.2,0.2-12.4,0.2-18.7,0.2
					c-12.1-0.1-24.3-0.6-36.4-1.4c-6.2-0.4-12.4-0.8-18.7-1.3c-6.4-0.5-16,0.4-19.7-5.9C944.1,380.9,941.5,382.5,942.5,384.1
					L942.5,384.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M994.6,225.6c5.5,18.9,6.7,37.9,7.6,57.5c1,20,1.9,39.9,2.9,59.9c0.1,1.9,3.1,1.9,3,0
					c-1-20.6-2-41.2-3-61.9c-0.9-19.2-2.3-37.7-7.7-56.3C996.9,223,994.1,223.8,994.6,225.6L994.6,225.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1005.8,345.4c11.7,4.1,24.1,4.8,36.3,5.9c12.7,1.2,24.8,2.5,37.5,0.4c12.7-2.1,25.3-4.6,37.9-7.5
					c11.5-2.7,22.1-6.6,31.3-14.1c1.5-1.2-0.6-3.3-2.1-2.1c-9.5,7.7-20.9,11.2-32.7,13.9c-11.7,2.7-23.4,5-35.2,6.9
					c-12.4,2-24.3,0.6-36.7-0.6c-11.9-1.2-24.1-1.8-35.5-5.8C1004.8,341.8,1004,344.7,1005.8,345.4L1005.8,345.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M995.9,225.7c21.5-6.1,44-6.5,66.2-7.6c22.5-1.2,44.7-1.9,67.2-0.2c1.9,0.1,1.9-2.9,0-3
					c-23.1-1.8-45.9-0.9-69,0.3c-21.9,1.1-44,1.7-65.2,7.7C993.2,223.3,994,226.2,995.9,225.7L995.9,225.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1127.8,216.3c0.3,19,2.8,37.9,6.2,56.6c1.7,9.5,3.7,18.9,5.8,28.3c2,9.3,3.7,18.7,6.9,27.6
					c0.6,1.8,3.5,1,2.9-0.8c-6.1-17.2-9-36.1-12.3-54c-3.6-19.1-6.1-38.3-6.5-57.7C1130.7,214.4,1127.7,214.4,1127.8,216.3
					L1127.8,216.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M780.6,179.1c0.5,5.4,0.9,10.8,1.4,16.2c0.2,1.9,3.2,1.9,3,0c-0.5-5.4-0.9-10.8-1.4-16.2
					C783.5,177.2,780.5,177.2,780.6,179.1L780.6,179.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M783.3,189.2c3-0.8,6-1.4,9-1.8c1.9-0.2,1.9-3.2,0-3c-3.3,0.4-6.6,1-9.8,1.9
					C780.7,186.8,781.5,189.7,783.3,189.2L783.3,189.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M791.5,175.8c0,3.4,0,6.8,0.1,10.2c0,2.7-0.3,5.5,0.5,8.1c0.6,1.8,3.5,1.1,2.9-0.8
					c-0.8-2.7-0.4-5.9-0.4-8.6c0-3,0-5.9-0.1-8.9C794.5,173.8,791.5,173.8,791.5,175.8L791.5,175.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M800.8,177.1c3.9,0.6,7.8-1.9,11.5-0.7c1.8,0.6,2.6-2.3,0.8-2.9c-4-1.2-7.6,1.3-11.5,0.7
					C799.8,173.9,798.9,176.8,800.8,177.1L800.8,177.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M806.1,176.7c1,5.2,1.5,10.5,1.5,15.8c0,1.9,3,1.9,3,0c0-5.6-0.5-11.1-1.6-16.6
					C808.6,174,805.7,174.8,806.1,176.7L806.1,176.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M820.3,173.3c-0.3,7-0.3,14-0.2,21c0,1.9,3,1.9,3,0c-0.1-7-0.1-14,0.2-21
					C823.3,171.4,820.3,171.4,820.3,173.3L820.3,173.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M820.3,173.4c2.3,5.1,7.1,8.2,8.8,13.6c0.4,1.1,2.2,1.6,2.7,0.4c2.4-5.2,4.9-10.3,7.3-15.5
					c-0.9-0.1-1.8-0.2-2.7-0.4c1.5,7.9,3,15.9,4.4,23.8c0.4,1.9,3.2,1.1,2.9-0.8c-1.5-7.9-3-15.9-4.4-23.8c-0.2-1.2-2.2-1.5-2.7-0.4
					c-2.4,5.2-4.9,10.3-7.3,15.5c0.9,0.1,1.8,0.2,2.7,0.4c-1.8-5.8-6.6-9-9.1-14.4C822.1,170.1,819.5,171.6,820.3,173.4L820.3,173.4
					z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M850.2,168.9c1.5,8.4,1.9,16.7,1.4,25.2c0,0.8,0.7,1.5,1.5,1.5c5.3,0,10.3-0.9,15.3-2.7
					c1.8-0.7,1-3.6-0.8-2.9c-4.7,1.7-9.5,2.6-14.5,2.6c0.5,0.5,1,1,1.5,1.5c0.5-8.8,0-17.4-1.6-26
					C852.7,166.2,849.8,167,850.2,168.9L850.2,168.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M782,215c19-1.4,38.1-2,57.2-1.9c1.9,0,1.9-3,0-3c-19.1-0.1-38.2,0.5-57.2,1.9
					C780.1,212.1,780.1,215.1,782,215L782,215z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M782.5,227.9c4.3,0.1,8.7,0.3,13,0c4-0.2,7.9-1,11.9-0.5c1.9,0.2,1.9-2.8,0-3c-4-0.5-7.9,0.3-11.9,0.5
					c-4.3,0.2-8.7,0.1-13,0C780.5,224.8,780.5,227.8,782.5,227.9L782.5,227.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M782.4,236.4c7.2,0.4,14.3-1.1,21.4,0c1.9,0.3,2.7-2.6,0.8-2.9c-7.4-1.1-14.8,0.3-22.2-0.1
					C780.4,233.3,780.4,236.3,782.4,236.4L782.4,236.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M784.1,255.5c6.5,0,13,0,19.6,0c1.9,0,1.9-3,0-3c-6.5,0-13,0-19.6,0C782.2,252.5,782.2,255.5,784.1,255.5
					L784.1,255.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M785.4,262.5c4.7,0,9.5,0,14.2,0c1.9,0,1.9-3,0-3c-4.7,0-9.5,0-14.2,0C783.4,259.5,783.4,262.5,785.4,262.5
					L785.4,262.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M782.5,273.6c6.7,0,13.4,0,20,0c1.9,0,1.9-3,0-3c-6.7,0-13.4,0-20,0C780.5,270.6,780.5,273.6,782.5,273.6
					L782.5,273.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M834.1,225.1c14.1,0,28.2,0,42.3,0c1.9,0,1.9-3,0-3c-14.1,0-28.2,0-42.3,0
					C832.2,222.1,832.2,225.1,834.1,225.1L834.1,225.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M835.1,236.9c9.2,0,18.4,0,27.5,0c1.9,0,1.9-3,0-3c-9.2,0-18.4,0-27.5,0
					C833.2,233.9,833.2,236.9,835.1,236.9L835.1,236.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M872.8,236.4c2.7,0,5.5,0,8.2,0c1.9,0,1.9-3,0-3c-2.7,0-5.5,0-8.2,0C870.8,233.4,870.8,236.4,872.8,236.4
					L872.8,236.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M838,255.7c11,0,22.1,0,33.1,0c1.9,0,1.9-3,0-3c-11,0-22.1,0-33.1,0C836.1,252.7,836.1,255.7,838,255.7
					L838,255.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M826.2,269.3c9.7,0,19.3,0,29,0c1.9,0,1.9-3,0-3c-9.7,0-19.3,0-29,0C824.2,266.3,824.2,269.3,826.2,269.3
					L826.2,269.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M838,276.7c7.3,0,14.6,0,22,0c1.9,0,1.9-3,0-3c-7.3,0-14.6,0-22,0C836.1,273.7,836.1,276.7,838,276.7
					L838,276.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M865.8,268.8c7,0,14,0,21,0c1.9,0,1.9-3,0-3c-7,0-14,0-21,0C863.8,265.8,863.8,268.8,865.8,268.8
					L865.8,268.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M900.5,197.5c6.4,0,12.9,0,19.3,0c1.9,0,1.9-3,0-3c-6.4,0-12.9,0-19.3,0
					C898.6,194.5,898.6,197.5,900.5,197.5L900.5,197.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M925.7,191.8c5,2.1,10.6,2,15.4,4.5c0-0.9,0-1.7,0-2.6c-3.5,2.5-7,5-10.4,7.5c-1.6,1.1-0.1,3.7,1.5,2.6
					c3.5-2.5,7-5,10.4-7.5c0.8-0.6,1.1-2,0-2.6c-5.1-2.6-10.9-2.6-16.1-4.8C924.7,188.2,923.9,191.1,925.7,191.8L925.7,191.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M903.5,210.8c2.3,0.6,4.6,1.2,7,1.6c0.6,0.1,6.1,0.4,6.4,1c-0.3-0.6-8,6.1-8.8,7c-1.3,1.4,0.8,3.5,2.1,2.1
					c2.4-2.5,5-4.6,8-6.4c1.5-0.9,3.2-2.2,2.3-4.2c-0.9-1.8-3.6-1.5-5.3-1.8c-3.6-0.5-7.2-1.3-10.8-2.3
					C902.4,207.4,901.6,210.3,903.5,210.8L903.5,210.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M902.9,236.2c10.3,0,20.6,0,30.9,0c1.9,0,1.9-3,0-3c-10.3,0-20.6,0-30.9,0C901,233.2,901,236.2,902.9,236.2
					L902.9,236.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M934.2,226.5c2.1,1.1,4.3,1.8,6.6,2.4c2.4,0.7,6.5,1.3,8,3.6c0-0.5,0-1,0-1.5c-1,1.7-3,3.2-4.4,4.6
					c-1.4,1.4-2.9,2.9-4.3,4.3c-1.2,1.2,0.3,3.2,1.8,2.4c0.2-0.1,0.5-0.3,0.7-0.4c1.7-1,0.2-3.5-1.5-2.6c-0.2,0.1-0.5,0.3-0.7,0.4
					c0.6,0.8,1.2,1.6,1.8,2.4c1.7-1.7,3.5-3.4,5.2-5.2c1.4-1.4,3.1-2.6,4-4.3c0.3-0.5,0.3-1.1,0-1.5c-3.1-4.8-11-4.7-15.7-7.1
					C934,223,932.5,225.6,934.2,226.5L934.2,226.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M902.7,253.3c7.2,0,14.5,0,21.7,0c1.9,0,1.9-3,0-3c-7.2,0-14.5,0-21.7,0
					C900.8,250.3,900.8,253.3,902.7,253.3L902.7,253.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M936.8,252.6c7.7,0,15.5,0,23.2,0c1.9,0,1.9-3,0-3c-7.7,0-15.5,0-23.2,0
					C934.8,249.6,934.8,252.6,936.8,252.6L936.8,252.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M905.4,273.1c7.9,0,15.8,0,23.7,0c1.9,0,1.9-3,0-3c-7.9,0-15.8,0-23.7,0
					C903.4,270.1,903.4,273.1,905.4,273.1L905.4,273.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M872.8,310.3c12.2,0,24.5,0,36.7,0c1.9,0,1.9-3,0-3c-12.2,0-24.5,0-36.7,0
					C870.8,307.3,870.8,310.3,872.8,310.3L872.8,310.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M886.5,320.9c11,0,22.1,0,33.1,0c1.9,0,1.9-3,0-3c-11,0-22.1,0-33.1,0C884.6,317.9,884.6,320.9,886.5,320.9
					L886.5,320.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M982.1,285.5c-5.3-5.7-12.1,7.4-12.8,11.3c-0.9,5.2,0.4,10.5,1.8,15.5c1.1,4.1,2.2,7.6,0.6,11.8
					c-1.4,3.5-4.2,6.7-7.6,8.2c-1.3,0.6-0.7,2.9,0.8,2.8c12.1-1.2,8.7,21.9,11.8,29.3c3,7,9.9,10.3,17.2,9.1
					c1.9-0.3,1.1-3.2-0.8-2.9c-15.6,2.5-14.8-14.8-16.2-25c-0.9-6.3-4.4-14.3-12.1-13.5c0.3,0.9,0.5,1.9,0.8,2.8
					c6.3-2.8,11-10.6,9.8-17.5c-0.9-5.5-3.2-10.6-3.4-16.2c-0.1-2.3,0.2-4.7,1-6.9c0.3-0.7,4.9-8.8,6.9-6.6
					C981.3,289.1,983.4,286.9,982.1,285.5L982.1,285.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1029.8,348.7c-2.5,2.7-1.7,5.9-0.3,9.1c1.2,2.7,4.1,5.9,4,8.9c0,5.5-6.4,8-10.6,9.6
					c-1.8,0.7-1,3.6,0.8,2.9c5-1.9,10.6-4.3,12.3-9.9c0.7-2.1,0.5-4.2-0.3-6.2c-1.2-3.1-7-9-3.8-12.3
					C1033.3,349.4,1031.1,347.3,1029.8,348.7L1029.8,348.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M1046.4,265.3c-15.6-6.4-30.4,6.7-28.1,22.9c2.3,16,21.1,19.4,30.1,6.6c1.1-1.6-1.5-3.1-2.6-1.5
					c-3.7,5.2-9.9,8.1-16.2,5.9c-6.2-2.2-8.7-8.4-8.7-14.7c0-13.1,12.6-21.1,24.6-16.3C1047.4,268.9,1048.2,266,1046.4,265.3
					L1046.4,265.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M1066.8,279.3c5.4-0.5,11-1.4,16.5-1.2c1.9,0.1,1.9-2.9,0-3c-5.5-0.2-11.1,0.7-16.5,1.2
					C1064.9,276.4,1064.9,279.4,1066.8,279.3L1066.8,279.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M1073.4,267.3c0.7,6.9,1,13.8,0.9,20.7c0,1.9,3,1.9,3,0c0.1-6.9-0.2-13.8-0.9-20.7
					C1076.2,265.4,1073.2,265.4,1073.4,267.3L1073.4,267.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M1094.1,273.6c5,0.7,10.1,0.5,15.1,0.3c1.9-0.1,1.9-3.1,0-3c-4.8,0.2-9.6,0.5-14.3-0.2
					C1093,270.5,1092.1,273.4,1094.1,273.6L1094.1,273.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M1100.9,262c0.3,7.8,0.5,15.6,0.8,23.5c0.1,1.9,3.1,1.9,3,0c-0.3-7.8-0.5-15.6-0.8-23.5
					C1103.8,260.1,1100.8,260.1,1100.9,262L1100.9,262z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1221,377.8c-4.8,43.1-1.4,86.7,9.9,128.5c0.5,1.9,3.4,1.1,2.9-0.8c-11.3-41.6-14.6-84.9-9.8-127.7
					C1224.2,375.9,1221.2,375.9,1221,377.8L1221,377.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1223.8,378.3c26.3,1.8,54,5.9,80-0.8c1.9-0.5,1.1-3.4-0.8-2.9c-25.6,6.7-53.1,2.5-79.2,0.7
					C1221.8,375.2,1221.8,378.2,1223.8,378.3L1223.8,378.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1301.8,376.3c-3.3,38.2,6.2,76.7,26.8,109c1,1.6,3.6,0.1,2.6-1.5c-20.3-31.9-29.6-69.8-26.4-107.5
					C1305,374.4,1302,374.4,1301.8,376.3L1301.8,376.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1233.1,507.2c15.3,5.6,34.2,2.8,49.7-0.7c16.6-3.8,32.5-10.6,46.7-19.9c1.6-1,0.1-3.6-1.5-2.6
					c-13.6,8.9-28.5,15.4-44.3,19.2c-15.4,3.7-34.5,6.7-49.8,1.1C1232.1,503.6,1231.3,506.5,1233.1,507.2L1233.1,507.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1241.6,394.9c-5.5,3.8-10.5,8-15.2,12.7c-0.9,0.9-0.3,2.6,1.1,2.6c7.4-0.2,14.9-0.4,22.3-0.5
					c1.9,0,1.9-3,0-3c-7.4,0.2-14.9,0.4-22.3,0.5c0.4,0.9,0.7,1.7,1.1,2.6c4.5-4.5,9.3-8.6,14.6-12.2
					C1244.7,396.5,1243.2,393.8,1241.6,394.9L1241.6,394.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1255.5,399c7.8-0.6,15.5-0.5,23.2,0.3c1.9,0.2,1.9-2.8,0-3c-7.7-0.8-15.5-0.9-23.2-0.3
					C1253.6,396.1,1253.6,399.1,1255.5,399L1255.5,399z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1279.7,394.2c4.8,1.1,9.5,2.5,14.2,4.2c-0.2-0.8-0.4-1.7-0.7-2.5c-3.2,2.5-6.3,5.1-9.3,7.9
					c-1.4,1.3,0.7,3.4,2.1,2.1c3-2.7,6.1-5.4,9.3-7.9c1-0.8,0.3-2.2-0.7-2.5c-4.6-1.8-9.3-3.2-14.2-4.2
					C1278.6,390.9,1277.8,393.7,1279.7,394.2L1279.7,394.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1228,428.7c4.4,0.3,8.7,0.5,13.1,0.8c1.9,0.1,1.9-2.9,0-3c-4.4-0.3-8.7-0.5-13.1-0.8
					C1226.1,425.6,1226.1,428.6,1228,428.7L1228,428.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1250.2,428.7c12.3-1.9,24.6-3.8,36.8-5.7c1.9-0.3,1.1-3.2-0.8-2.9c-12.3,1.9-24.6,3.8-36.8,5.7
					C1247.5,426.1,1248.3,429,1250.2,428.7L1250.2,428.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1230.2,438.5c3.1,0,6.3,0,9.4,0c1.9,0,1.9-3,0-3c-3.1,0-6.3,0-9.4,0
					C1228.3,435.5,1228.3,438.5,1230.2,438.5L1230.2,438.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1230.9,445.8c3.6,0.3,7.1,0,10.5-0.8c1.9-0.4,1.1-3.3-0.8-2.9c-3.2,0.7-6.4,0.9-9.7,0.7
					C1229,442.6,1229,445.6,1230.9,445.8L1230.9,445.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1229.8,458.8c13.3-1.4,27.9-1.4,40.6-5.8c1.8-0.6,1-3.5-0.8-2.9c-12.5,4.3-26.8,4.3-39.8,5.7
					C1227.9,456,1227.9,459,1229.8,458.8L1229.8,458.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1254.1,442.1c13.8-1.5,27.5-3.9,41-7.2c1.9-0.5,1.1-3.4-0.8-2.9c-13.2,3.3-26.6,5.6-40.2,7.1
					C1252.2,439.3,1252.2,442.3,1254.1,442.1L1254.1,442.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1233.1,471.1c3.1,0.3,6.1,0.1,9.1-0.7c1.9-0.5,1.1-3.4-0.8-2.9c-2.7,0.7-5.5,0.9-8.3,0.6
					C1231.2,467.9,1231.2,470.9,1233.1,471.1L1233.1,471.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1255.4,467.9c4.5,0.5,8.8-0.8,13.2-1.8c5.4-1.2,10.8-2.4,16.1-3.6c1.9-0.4,1.1-3.3-0.8-2.9
					c-4.7,1.1-9.5,2.1-14.2,3.2c-4.7,1-9.5,2.6-14.3,2.1C1253.5,464.6,1253.5,467.6,1255.4,467.9L1255.4,467.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1249.3,473.3c-2.5,0.7-4.5,2.4-5.1,5c-0.2,1-0.1,2.2,0.2,3.2c0.3,0.8,4.5,6.1,2.3,6.7
					c-0.9,0.2-1.5,1.4-0.9,2.2c2.4,3.3,2.1,8.1,5.7,10.6c2.3,1.6,4.8,1.7,6.9-0.2c1.4-1.3-0.7-3.4-2.1-2.1c-3.6,3.4-6.5-7.8-7.9-9.8
					c-0.3,0.7-0.6,1.5-0.9,2.2c1.8-0.5,2.8-1.5,3-3.4c0.5-3.1-6.8-9.8-0.4-11.5C1252,475.7,1251.2,472.8,1249.3,473.3L1249.3,473.3z
					"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1257,486.3c9-0.4,17.9-1.1,26.8-2.1c1.9-0.2,1.9-3.2,0-3c-8.9,1-17.8,1.7-26.8,2.1
					C1255.1,483.4,1255.1,486.4,1257,486.3L1257,486.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1289.6,470.4c0.8,0.1,2.1-0.2,2.9,0c4.3,1.2,2.5,2.2,2.8,4.4c0.2,1.7,0,3.2,0.5,4.9
					c0.5,1.8,1.5,3.5,2.1,5.3c0.9,2.6,1,3.1-0.9,5.4c-1.8,2.1-1.8,2.1-4.2,2.5c-1.9,0.3-1.1,3.2,0.8,2.9c3-0.5,6-1.6,7.4-4.5
					c1.6-3.4-0.1-6.7-1.4-9.9c-1-2.4-1.4-4.6-1.3-7.2c0-1.4,0.2-2.9-0.4-4.2c-1.7-3.5-5.2-2.2-8.3-2.6
					C1287.7,467.2,1287.7,470.2,1289.6,470.4L1289.6,470.4z"
            />
          </g>
        </g>
      </g>
      <g id="ground_grey">
        <g>
          <g>
            <path
              class="st2"
              d="M240,859c5.5,39,10,78.1,13.7,117.3c0.2,1.9,3.2,1.9,3,0c-3.7-39.5-8.3-78.8-13.8-118.1
					C242.6,856.3,239.8,857.1,240,859L240,859z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M253.8,977.9c32.2,16,70.5,16.3,103,1.1c1.7-0.8,0.2-3.4-1.5-2.6c-31.5,14.8-68.9,14.3-100-1.1
					C253.6,974.4,252.1,977,253.8,977.9L253.8,977.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M359,861.2c-0.1,38.9-0.9,77.7-2.5,116.5c-0.1,1.9,2.9,1.9,3,0c1.6-38.8,2.4-77.7,2.5-116.5
					C362,859.3,359,859.3,359,861.2L359,861.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M240.8,859.5c39,11.8,80.1,7.6,119.9,3.1c1.9-0.2,1.9-3.2,0-3c-39.6,4.4-80.3,8.7-119.1-3
					C239.8,856,239,858.9,240.8,859.5L240.8,859.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M255.6,963c3.4-6.8,11.1,2.8,13.8,5.2c2.5,2.2,5.4,3.5,8.8,3.3c8.4-0.4,15.5-8.7,24.1-6.6
					c8.4,2.1,12.8,10.7,22.4,9.8c10.8-1,21.8-13.8,32.8-7.4c1.7,1,3.2-1.6,1.5-2.6c-10.5-6.1-20.2,2.7-30,6
					c-7.5,2.5-12.4-0.4-18.3-4.6c-3.6-2.5-7.3-4.7-11.9-4.4c-10.5,0.6-19.3,12.3-28.9,2.9c-2.8-2.8-5.1-5.8-9.3-6.5
					c-3.2-0.5-6.1,0.5-7.6,3.4C252.1,963.2,254.7,964.7,255.6,963L255.6,963z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M252.8,945.7c34.5,9.4,71,8.8,105.1-2c1.8-0.6,1.1-3.5-0.8-2.9c-33.6,10.6-69.6,11.2-103.5,2
					C251.7,942.3,250.9,945.1,252.8,945.7L252.8,945.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M247.9,910.9c36.7,9,75.3,8.2,111.5-2.5c1.8-0.5,1.1-3.4-0.8-2.9c-35.8,10.6-73.7,11.4-109.9,2.5
					C246.8,907.5,246,910.4,247.9,910.9L247.9,910.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M246.2,884.9c6-4.5,11.9,2.1,16.5,5.4c3.6,2.6,7.5,3.9,11.9,3.4c9.1-1.2,17.8-10.6,27.3-8.2
					c5.2,1.3,8.5,6.4,13.8,7.3c4,0.7,7.7-1.2,10.9-3.3c5.4-3.4,9.8-6.1,16.5-5.6c6.1,0.4,11.8,1.9,18,0.9c1.9-0.3,1.1-3.2-0.8-2.9
					c-6.9,1-13.6-1.4-20.5-1.1c-5,0.2-8.9,2.2-13,5c-2.3,1.5-4.6,3.3-7.3,3.9c-4.1,1-7.2-1.4-10.3-3.6c-3.6-2.5-7.2-4.3-11.8-3.8
					c-9.3,0.9-17.8,10.4-27.4,8.4c-8.3-1.7-15.9-15.5-25.5-8.4C243.2,883.5,244.7,886.1,246.2,884.9L246.2,884.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M267.7,861.6c-4.6-4.7-18.9-20.1-11-26.7c0.5-0.4,0.6-1.3,0.2-1.8c-6.2-8.9-13-18.4-13.7-29.6
					c-0.3-4.9,1.8-8.8,2.2-13.4c0.4-4.4-1.9-9.3-3-13.5c-5.4-21.8-9.2-45.6-7.1-68.1c0.5-5.5,1.5-11.2,3.7-16.3
					c4.2-10,12.7-0.8,16.3,4.2c6,8.4,10.1,17.9,13.6,27.6c7.5,20.9,13.5,42.4,18.4,64.1c5.7,25.2,9.6,50.8,11.8,76.6
					c0.2,1.9,3.2,1.9,3,0c-2.2-25.4-6-50.6-11.5-75.5c-5.4-24.7-12.1-49.7-21.5-73.1c-3.8-9.4-8.5-19.9-16-27
					c-5.6-5.4-13.2-6.4-16.6,1.6c-4.3,10-4.7,21.4-4.4,32.2c0.4,12.9,1.8,25.9,4.1,38.6c1.3,7,2.8,13.9,4.6,20.7
					c1.1,4.1,1.9,7.1,0.7,11.1c-0.9,2.8-1.3,5.7-1.3,8.7c0,12.4,7.3,23.1,14.1,32.8c0.1-0.6,0.2-1.2,0.2-1.8
					c-9.8,8.3,5.1,25.1,11,31C267,865,269.1,862.9,267.7,861.6L267.7,861.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M291.2,783.5c0.9-8.8,1.8-17.7,5-26c1.9-4.9,4.6-8.9,7.9-12.9c2.3-2.8,3.9-5.7,6-8.6
					c6.1-8.6,11.9,0.9,13,7.1c0.9,5.5,1.3,11.1,1.5,16.7c0.4,10.8-0.2,21.7-1.8,32.4c-0.2,1.6,1.8,2.7,2.7,1.2
					c2.3-3.7,9.4-11.5,12.9-3.2c1.1,2.6,0.4,6.4,0.3,9.1c-0.4,7.7-1.1,15.3-2.2,22.9c-2,13.9-5.3,27.7-9.7,41.1
					c-0.6,1.8,2.3,2.6,2.9,0.8c6.3-19.5,10.3-39.7,11.7-60.1c0.4-5.7,2.6-16.4-4.2-19.6c-6.2-2.9-11.4,2.8-14.4,7.5
					c0.9,0.4,1.8,0.8,2.7,1.2c2-13.6,2.6-27.2,1.4-40.9c-0.7-7.5-1.4-23.6-12.8-22.5c-4.2,0.4-6.3,4.1-8.3,7.3
					c-3.1,5.2-7.6,9.2-10.4,14.6c-5.2,9.9-6.3,21.2-7.4,32.1C288,785.4,291,785.4,291.2,783.5L291.2,783.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M241.1,690.2c21.5,56.4,33.1,115.7,44.5,174.7c0.4,1.9,3.3,1.1,2.9-0.8c-11.4-59-23-118.4-44.5-174.7
					C243.3,687.6,240.4,688.4,241.1,690.2L241.1,690.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M239.7,774.7c14.6,8.5,26.3,20.7,34.2,35.6c0.9,1.7,3.5,0.2,2.6-1.5c-8.1-15.3-20.3-28-35.2-36.7
					C239.5,771.1,238,773.7,239.7,774.7L239.7,774.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M267,716.9c-1.8,16.7-3.4,33.5-1.4,50.2c0.2,1.9,3.2,1.9,3,0c-2-16.8-0.4-33.5,1.4-50.2
					C270.2,715,267.2,715,267,716.9L267,716.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M314.9,733c-2.3,42-4.5,84.1-6.8,126.1c-0.1,1.9,2.9,1.9,3,0c2.3-42,4.5-84.1,6.8-126.1
					C318,731,315,731,314.9,733L314.9,733z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M288.5,781.8c5.2,16.9,11.7,33.3,19.6,49.1c0.9,1.7,3.5,0.2,2.6-1.5c-7.8-15.6-14.2-31.8-19.3-48.4
					C290.8,779.2,287.9,779.9,288.5,781.8L288.5,781.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M311.4,845.6c11.3-6.4,20.5-15.5,27.1-26.7c1-1.7-1.6-3.2-2.6-1.5c-6.3,10.8-15.2,19.5-26,25.6
					C308.2,844,309.7,846.5,311.4,845.6L311.4,845.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M241.9,860.1c6-0.4,15.4-2.5,19.8,2.9c0.6-0.8,1.2-1.6,1.8-2.4c-3.9-2.1-8-3.5-12.4-4.3
					c-0.3,1-0.5,1.9-0.8,2.9c4.8,0.8,9.5,1.9,14.2,3.3c1.9,0.6,2.6-2.3,0.8-2.9c-4.7-1.4-9.4-2.5-14.2-3.3c-1.9-0.3-2.7,2.5-0.8,2.9
					c4.2,0.8,7.9,2.1,11.7,4c1.6,0.8,2.9-1.1,1.8-2.4c-5.1-6.3-14.9-4.2-21.9-3.8C240,857.2,240,860.2,241.9,860.1L241.9,860.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M331.4,861.2c-0.2-1,12.1-0.4,12.8-0.3c4.2,0.2,8.5,0.4,12.7,0.5c0-1,0-2,0-3c-7.9,0.4-15.8,0.8-23.7,1.1
					c-1.9,0.1-1.9,2.9,0,3c4.4,0.2,8.8,0.3,13.2,0.2c1.9,0,1.9-3,0-3c-4.4,0.1-8.8,0.1-13.2-0.2c0,1,0,2,0,3
					c7.9-0.4,15.8-0.8,23.7-1.1c1.9-0.1,1.9-2.9,0-3c-7.7-0.3-15.7-1.3-23.4-1c-2.9,0.1-5.7,1.1-4.9,4.6
					C328.9,863.9,331.8,863.1,331.4,861.2L331.4,861.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M452.2,1115c15-18.8,29.9-37.5,44.9-56.3c13.7-17.1,27.4-34.3,43.4-49.3c1.4-1.3-0.7-3.4-2.1-2.1
					c-16,15.1-29.7,32.2-43.4,49.3c-15,18.8-29.9,37.5-44.9,56.3C448.9,1114.4,451,1116.5,452.2,1115L452.2,1115z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M538.4,1008.8c127.1,0,254.1,0,381.2,0c35.9,0,71.8,0,107.8,0c1.9,0,1.9-3,0-3c-127.1,0-254.1,0-381.2,0
					c-35.9,0-71.8,0-107.8,0C536.4,1005.8,536.4,1008.8,538.4,1008.8L538.4,1008.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1026.9,1009.1c35.7,36.7,69.3,75.3,100.5,115.8c1.2,1.5,3.8,0,2.6-1.5c-31.4-40.7-65.1-79.6-101-116.4
					C1027.7,1005.6,1025.5,1007.7,1026.9,1009.1L1026.9,1009.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M288.2,1020.2c46.5,0,92.9,0,139.4,0c1.9,0,1.9-3,0-3c-46.5,0-92.9,0-139.4,0
					C286.3,1017.2,286.3,1020.2,288.2,1020.2L288.2,1020.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M548.6,959.7c84.6,0,169.2,0,253.8,0c84.3,0,168.6,0,252.8,0c47.6,0,95.3,0,142.9,0c1.9,0,1.9-3,0-3
					c-84.6,0-169.2,0-253.8,0c-84.3,0-168.6,0-252.8,0c-47.6,0-95.3,0-142.9,0C546.7,956.7,546.7,959.7,548.6,959.7L548.6,959.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1263.9,961c70.6,0,141.1,0,211.7,0c1.9,0,1.9-3,0-3c-70.6,0-141.1,0-211.7,0C1262,958,1262,961,1263.9,961
					L1263.9,961z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1085.1,1004.4c38.6,0,77.1,0,115.7,0c1.9,0,1.9-3,0-3c-38.6,0-77.1,0-115.7,0
					C1083.2,1001.4,1083.2,1004.4,1085.1,1004.4L1085.1,1004.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1257.3,1016.2c29.4,0,58.7,0,88.1,0c1.9,0,1.9-3,0-3c-29.4,0-58.7,0-88.1,0
					C1255.4,1013.2,1255.4,1016.2,1257.3,1016.2L1257.3,1016.2z"
            />
          </g>
        </g>
      </g>
      <g id="ground_black">
        <g>
          <g>
            <path
              class="st3"
              d="M444.5,957.7c36.3,0,72.6,0,108.9,0c1.9,0,1.9-3,0-3c-36.3,0-72.6,0-108.9,0
					C442.5,954.7,442.5,957.7,444.5,957.7L444.5,957.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M546.9,970.7c70.6,0,141.1,0,211.7,0c20.2,0,40.4,0,60.6,0c1.9,0,1.9-3,0-3c-70.6,0-141.1,0-211.7,0
					c-20.2,0-40.4,0-60.6,0C545,967.7,545,970.7,546.9,970.7L546.9,970.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M758.3,957.7c95.6,0,191.1,0,286.7,0c27.2,0,54.4,0,81.6,0c1.9,0,1.9-3,0-3c-95.6,0-191.1,0-286.7,0
					c-27.2,0-54.4,0-81.6,0C756.4,954.7,756.4,957.7,758.3,957.7L758.3,957.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1126.6,977.2c67.2,0,134.3,0,201.5,0c19.3,0,38.6,0,57.9,0c1.9,0,1.9-3,0-3c-67.2,0-134.3,0-201.5,0
					c-19.3,0-38.6,0-57.9,0C1124.7,974.2,1124.7,977.2,1126.6,977.2L1126.6,977.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M273.3,1079.6c27.2,0,54.5,0,81.7,0c1.9,0,1.9-3,0-3c-27.2,0-54.5,0-81.7,0
					C271.3,1076.6,271.3,1079.6,273.3,1079.6L273.3,1079.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M968.4,1061.5c39.3,0,78.7,0,118,0c1.9,0,1.9-3,0-3c-39.3,0-78.7,0-118,0
					C966.4,1058.5,966.4,1061.5,968.4,1061.5L968.4,1061.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1279.6,1065.3c35.4,0,70.9,0,106.3,0c1.9,0,1.9-3,0-3c-35.4,0-70.9,0-106.3,0
					C1277.7,1062.3,1277.7,1065.3,1279.6,1065.3L1279.6,1065.3z"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="table__x26__pc_x27_s">
      <g id="table">
        <path
          class="st4"
          d="M386.3,701.5c0,127,0,254,0,380.9c8.8-0.9,17.5-1.8,26.3-2.8c0-127,0-254,0-380.9
			C403.9,699.6,395.1,700.5,386.3,701.5z"
        />
        <path
          class="st4"
          d="M1146.8,705.6c-0.2,124.7-0.5,249.3-0.7,374c8.5,0,17.1,0,25.6,0c0-125.8,0-251.7,0-377.5
			C1163.5,703.3,1155.2,704.5,1146.8,705.6z"
        />
        <path
          d="M515.9,955.6c-1-53.7-1.3-108.7-0.7-164.8c0.4-36.1,1.1-71.6,2.1-106.7c-10.6-0.2-21.2-0.5-31.9-0.7
			c-0.2,90.3-0.5,180.5-0.7,270.8C495.1,954.7,505.5,955.2,515.9,955.6z"
        />
        <path
          d="M1045,689.7c0.2,88.2,0.5,176.4,0.7,264.6c9.5-0.2,18.9-0.5,28.4-0.7c0.7-89.6,1.4-179.2,2.1-268.7
			C1065.8,686.4,1055.4,688.1,1045,689.7z"
        />
        <path
          d="M343.4,693.8c-0.2,7.4-0.5,14.8-0.7,22.2c286.7,0.5,573.5,0.9,860.2,1.4c-0.7-7.8-1.4-15.7-2.1-23.5
			c-92.9-4.1-187.8-6.9-284.7-8.3C717.5,682.6,526.4,685.8,343.4,693.8z"
        />
        <path
          class="st4"
          d="M343.4,693.1c285.8,0.2,571.6,0.5,857.5,0.7c-30.5-28.9-61-57.7-91.4-86.6c-230.6,0.2-461.3,0.5-691.9,0.7
			C392.8,636.3,368.1,664.7,343.4,693.1z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M343.5,695.1c24.7-29.5,50.1-58.4,76.3-86.6c1.3-1.4-0.8-3.5-2.1-2.1c-26.2,28.2-51.6,57-76.3,86.6
					C340.2,694.4,342.3,696.6,343.5,695.1L343.5,695.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M340.8,695.1c0,6.2,0,12.4,0,18.7c0,1.9,3,1.9,3,0c0-6.2,0-12.4,0-18.7
					C343.8,693.2,340.8,693.2,340.8,695.1L340.8,695.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M344.8,717.8c111.9,0,223.7,0,335.6,0c111.2,0,222.4,0,333.6,0c62.6,0,125.2,0,187.8,0c1.9,0,1.9-3,0-3
					c-111.9,0-223.7,0-335.6,0c-111.2,0-222.4,0-333.6,0c-62.6,0-125.2,0-187.8,0C342.8,714.8,342.8,717.8,344.8,717.8L344.8,717.8z
					"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1204.5,715c0-7.5,0-14.9,0-22.4c0-1.9-3-1.9-3,0c0,7.5,0,14.9,0,22.4C1201.5,717,1204.5,717,1204.5,715
					L1204.5,715z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M342.3,717.8c1.9,0,1.9-3,0-3C340.4,714.8,340.3,717.8,342.3,717.8L342.3,717.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M343.5,695.4c111.9,0,223.7,0,335.6,0c111.2,0,222.4,0,333.6,0c62.6,0,125.2,0,187.8,0c1.9,0,1.9-3,0-3
					c-111.9,0-223.7,0-335.6,0c-111.2,0-222.4,0-333.6,0c-62.6,0-125.2,0-187.8,0C341.6,692.4,341.6,695.4,343.5,695.4L343.5,695.4z
					"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M420.6,609.6c89.9,0,179.8,0,269.6,0c89.2,0,178.4,0,267.6,0c50.6,0,101.2,0,151.8,0c1.9,0,1.9-3,0-3
					c-89.9,0-179.8,0-269.6,0c-89.2,0-178.4,0-267.6,0c-50.6,0-101.2,0-151.8,0C418.7,606.6,418.7,609.6,420.6,609.6L420.6,609.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1108.1,609.7c30.3,28.1,60.7,56.3,91,84.4c1.4,1.3,3.5-0.8,2.1-2.1c-30.3-28.1-60.7-56.3-91-84.4
					C1108.8,606.3,1106.7,608.4,1108.1,609.7L1108.1,609.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M385.6,716.3c0,94.6,0,189.2,0,283.8c0,26.9,0,53.8,0,80.7c0,1.9,3,1.9,3,0c0-94.6,0-189.2,0-283.8
					c0-26.9,0-53.8,0-80.7C388.6,714.3,385.6,714.3,385.6,716.3L385.6,716.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M411.7,717.5c0,94,0,188,0,282c0,26.7,0,53.3,0,80c0,1.9,3,1.9,3,0c0-94,0-188,0-282c0-26.7,0-53.3,0-80
					C414.7,715.6,411.7,715.6,411.7,717.5L411.7,717.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M387.1,1083.7c7.9-0.5,15.7-1,23.6-1.4c1.9-0.1,1.9-3.1,0-3c-7.9,0.5-15.7,1-23.6,1.4
					C385.2,1080.9,385.1,1083.9,387.1,1083.7L387.1,1083.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1144.3,716.3c0,94.6,0,189.2,0,283.8c0,26.9,0,53.8,0,80.7c0,1.9,3,1.9,3,0c0-94.6,0-189.2,0-283.8
					c0-26.9,0-53.8,0-80.7C1147.3,714.3,1144.3,714.3,1144.3,716.3L1144.3,716.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1170.4,717.5c0,94,0,188,0,282c0,26.7,0,53.3,0,80c0,1.9,3,1.9,3,0c0-94,0-188,0-282c0-26.7,0-53.3,0-80
					C1173.4,715.6,1170.4,715.6,1170.4,717.5L1170.4,717.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1147,1081.7c7.9-0.5,15.7-1,23.6-1.4c1.9-0.1,1.9-3.1,0-3c-7.9,0.5-15.7,1-23.6,1.4
					C1145.1,1078.8,1145.1,1081.8,1147,1081.7L1147,1081.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M484.2,717.7c0,78.5,0,157,0,235.5c0,1.9,3,1.9,3,0c0-78.5,0-157,0-235.5
					C487.2,715.8,484.2,715.8,484.2,717.7L484.2,717.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M514.1,719.1c0,78,0,156.1,0,234.1c0,1.9,3,1.9,3,0c0-78,0-156.1,0-234.1
					C517.1,717.2,514.1,717.2,514.1,719.1L514.1,719.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M487,956.1c9.5,0,19.1,0,28.6,0c1.9,0,1.9-3,0-3c-9.5,0-19.1,0-28.6,0C485.1,953.1,485.1,956.1,487,956.1
					L487,956.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1045,716.4c0,78.9,0,157.9,0,236.8c0,1.9,3,1.9,3,0c0-78.9,0-157.9,0-236.8
					C1048,714.5,1045,714.4,1045,716.4L1045,716.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1073.5,719.1c0,78,0,156.1,0,234.1c0,1.9,3,1.9,3,0c0-78,0-156.1,0-234.1
					C1076.5,717.2,1073.5,717.2,1073.5,719.1L1073.5,719.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1047.8,956.1c9.1,0,18.1,0,27.2,0c1.9,0,1.9-3,0-3c-9.1,0-18.1,0-27.2,0
					C1045.9,953.1,1045.9,956.1,1047.8,956.1L1047.8,956.1z"
            />
          </g>
        </g>
      </g>
      <g id="mug">
        <path
          class="st4"
          d="M945.6,590.9c-1.7,2.2-2.1,4.5-2.3,6.5c-2.2,16-1.4,28.1-1.4,28.1c0.4,6,0.8,14.9,1.1,26.3
			c4.3,4.5,8.4,6.3,11,7.2c7.5,2.5,14.6,0.8,28.4-2.7c5.6-1.4,10-2.8,13-3.8c2.2-4.1,2.9-7.6,3.1-9.9c0.4-4.9-1.1-7.2,0.7-9.7
			c1.8-2.5,4.4-2,5.6-4.7c0.7-1.6,0.6-4-0.5-4.9c-1.9-1.3-5.3,2.1-7,0.7c-0.5-0.4-0.5-0.8-0.7-4.5c-0.3-5-0.4-5.6,0.2-6.1
			c0.5-0.6,2.2-0.6,5.4-0.5c2.4,0,3.5,0,4,0.4c1.9,1.5,1.1,6.4-0.7,9.9c-2.3,4.4-5.8,5.8-5.4,6.7c0.5,0.9,4.9,0,8.5-2.2
			c2.1-1.3,5.2-3.1,5.9-6.5c1.2-5-3.6-9.4-4.3-10.1c-5.9-5.5-14.5-3.7-15.6-3.4c0.1-4.8,0.2-9.6,0.4-14.4c-12.9-5.7-23.7-7-30.4-7.2
			C950.5,585.6,946.8,589.2,945.6,590.9z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M941.2,595.4c-1.5,18.4-1.7,36.7-0.6,55.1c0.1,1.9,3.1,1.9,3,0c-1.1-18.4-0.9-36.8,0.6-55.1
					C944.4,593.5,941.4,593.5,941.2,595.4L941.2,595.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M940.8,651.3c13.4,14.8,34.3,10.2,50.6,3.9c1.8-0.7,1-3.6-0.8-2.9c-15.3,5.9-35.1,10.9-47.7-3.1
					C941.6,647.8,939.5,649.9,940.8,651.3L940.8,651.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M944.1,595.3c3.9-7.1,17.1-7.8,24.3-7.6c8.6,0.2,16.8,2.5,24.4,6.6c1.7,0.9,3.2-1.7,1.5-2.6
					c-8.9-4.9-19-7.4-29.2-7c-7.7,0.3-19.4,1.6-23.6,9.1C940.6,595.5,943.2,597,944.1,595.3L944.1,595.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M993.1,594.6c0,4.5,0,9,0,13.5c0,1,1,1.6,1.9,1.4c5-1.1,12.9,0,15.8,4.9c5.7,9.4-5.4,11.4-11.1,15.2
					c-2.5,1.6-2.9,3.3-2.9,6.2c0,6.5,0,14-6.7,17.3c-1.7,0.9-0.2,3.4,1.5,2.6c5.9-2.9,7.8-9,8.1-15.2c0.1-2.8-0.8-6,1.4-8.3
					c1.1-1.1,3.3-1.6,4.7-2.3c3-1.4,5.8-2.9,7.8-5.6c3.8-5.1,1.7-10.4-2.8-14.1c-4.7-3.8-10.8-4.9-16.6-3.7c0.6,0.5,1.3,1,1.9,1.4
					c0-4.5,0-9,0-13.5C996,592.7,993,592.7,993.1,594.6L993.1,594.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M995.4,613.5c-0.4,3.7-0.2,7.4,0.6,11c0.4,1.9,3.3,1.1,2.9-0.8c-0.7-3.4-0.9-6.8-0.5-10.2
					C998.6,611.6,995.6,611.6,995.4,613.5L995.4,613.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M997.7,616.5c1.5-1.3,3.7-2.2,5.7-1.6c1.9,0.6,3.4,2.8,2.5,4.8c-1.3,2.7-5.7,2.7-8.3,2.7c-1.9,0-1.9,3,0,3
					c4.7,0.1,12.3-1.3,11.5-7.7c-0.3-2.6-2.4-4.9-4.9-5.7c-3.1-0.9-6.3,0.3-8.7,2.4C994.1,615.6,996.2,617.7,997.7,616.5
					L997.7,616.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M952.7,590.6c-2,1.6-5.3,0.8-5.6,4.2c-0.3,2.7,2,4.6,4.3,5.1c5.2,1.1,11.6-0.2,17-0.1
					c6.4,0.1,12.9,0.6,19.3,1.3c0.8,0.1,1.5-0.8,1.5-1.5c0-1.7,0-1.8-0.9-3.3c-1-1.6-3.6-0.1-2.6,1.5c0.2,0.6,0.3,1.2,0.5,1.8
					c0.5-0.5,1-1,1.5-1.5c-7.7-0.9-15.4-1.3-23.1-1.3c-1.9,0-3.9,0-5.8,0.1c-1.4,0-4.3,0.8-5.5,0.2c-0.6-0.9-1.3-1.8-1.9-2.7
					c0.5-0.1,1.1-0.2,1.6-0.4c0.5-0.4,1.4-0.8,1.9-1.2C956.4,591.5,954.2,589.4,952.7,590.6L952.7,590.6z"
            />
          </g>
        </g>
        <path
          class="st5"
          d="M970.6,586.5c-0.4-1.4-8.9-0.8-16.6,1.6c-4.4,1.3-7.9,3.1-10.3,4.3c-0.8,11-1.3,22.4-1.6,34.4
			c-0.2,8.5-0.2,16.8,0,24.9c2.8,2.1,9.6,6.7,19.4,7.5c6.6,0.5,11.9-0.9,15-2c-1.7-19.5-3.4-39-5.1-58.5c-8-0.1-14.3-0.8-14.6-2.8
			C956.2,592.8,971.2,588.4,970.6,586.5z"
        />
        <path
          class="st6"
          d="M944.1,598.7c4.2,13.3,8.6,26.8,13,40.3c2.2,6.8,4.5,13.5,6.7,20.2c-2.8-0.1-7.3-0.6-12.2-2.8
			c-4.2-1.8-7.2-4.2-9.1-5.9c-0.6-8.8-0.9-18.6-0.4-29.2C942.5,613.2,943.2,605.6,944.1,598.7z"
        />
      </g>
      <g id="compuers">
        <path
          class="st4"
          d="M975.6,425.6c-0.9,4.6-2,11.4-3.2,19.7c-3.7,27.3-3.6,48.5-3.5,65c0.1,12.1,0.6,27.8,2.1,46.3
			c4.8,1.7,12.6,4.3,22.5,6.7c17.1,4.2,27.1,4.6,55.5,8.4c17.5,2.4,13.4,2.2,28.8,4.2c22.7,3,37,4.9,55.1,5.3
			c8.3,0.2,19.9,0.1,34.1-1.1c2.5-10.9,5.2-24.7,7-40.7c0.5-4,2.4-22.2,2.1-47c-0.2-15.9-1.4-35.9-4.9-59c-3.8-0.9-9.1-2.2-15.4-3.5
			c-7.5-1.6-33.7-6.9-69.5-9.1c-37.9-2.4-66.9-0.1-76.5,0.7C995.8,422.6,984.2,424.2,975.6,425.6z"
        />
        <path
          class="st4"
          d="M320.9,501.7c17.3,44.1,34.5,88.2,51.8,132.3c50.6-30.7,101.2-61.5,151.8-92.2
			c-14.6-35.8-29.1-71.7-43.7-107.5c-25.7,8.7-52.8,18.9-81.1,30.8C371.3,477.1,345.1,489.4,320.9,501.7z"
        />
        <path
          class="st4"
          d="M371.8,635.8c18.8,11.4,39.7,22.9,62.9,33.8c14.4,6.9,28.5,12.9,41.9,18.3c23.1-15.6,46.2-31.2,69.2-47
			c22.7-15.6,45.4-31.2,68-47c-28.8-17.1-57.7-34.1-86.5-51.2C475.4,573.8,423.6,604.8,371.8,635.8z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M400,638c43.7-26.4,88.1-52.1,129.7-81.8c1.6-1.1,0.1-3.7-1.5-2.6c-41.6,29.7-86,55.4-129.7,81.8
					C396.9,636.4,398.4,639,400,638L400,638z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M416.6,648.3c43.7-26.4,88.1-52.1,129.7-81.8c1.6-1.1,0.1-3.7-1.5-2.6c-41.6,29.7-86,55.4-129.7,81.8
					C413.5,646.7,415,649.3,416.6,648.3L416.6,648.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M436.9,658c43.7-26.4,88.1-52.1,129.7-81.8c1.6-1.1,0.1-3.7-1.5-2.6c-41.6,29.7-86,55.4-129.7,81.8
					C433.7,656.4,435.2,659,436.9,658L436.9,658z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M453.5,668.3c43.7-26.4,88.1-52.1,129.7-81.8c1.6-1.1,0.1-3.7-1.5-2.6c-41.6,29.7-86,55.4-129.7,81.8
					C450.3,666.7,451.8,669.3,453.5,668.3L453.5,668.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M413.6,628.6c11,7,22.1,13.9,33.1,20.9c1.6,1,3.1-1.6,1.5-2.6c-11-7-22.1-13.9-33.1-20.9
					C413.5,625,412,627.6,413.6,628.6L413.6,628.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M430.8,618.6c10.3,7.3,20.6,14.5,30.9,21.8c1.6,1.1,3.1-1.5,1.5-2.6c-10.3-7.3-20.6-14.5-30.9-21.8
					C430.8,614.9,429.3,617.5,430.8,618.6L430.8,618.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M447.3,608.5c9.9,7.2,19.7,14.6,29.2,22.4c1.5,1.2,3.6-0.9,2.1-2.1c-9.7-7.9-19.7-15.5-29.8-22.8
					C447.2,604.7,445.7,607.3,447.3,608.5L447.3,608.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M464.5,598.7c9.7,6.9,18.9,14.4,27.7,22.4c1.4,1.3,3.5-0.8,2.1-2.1c-9-8.2-18.4-15.8-28.3-22.9
					C464.4,595,462.9,597.6,464.5,598.7L464.5,598.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M480.3,590.1c9,7.4,17.4,15.3,25.3,23.8c1.3,1.4,3.4-0.7,2.1-2.1c-7.9-8.5-16.4-16.4-25.3-23.8
					C481,586.8,478.8,588.9,480.3,590.1L480.3,590.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M492.8,580.3c9.8,7.7,18.7,16.2,26.9,25.5c1.3,1.5,3.4-0.7,2.1-2.1c-8.2-9.3-17.1-17.8-26.9-25.5
					C493.5,577,491.3,579.1,492.8,580.3L492.8,580.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M508.4,569.6c9.6,7.7,19,15.7,27.9,24.1c1.4,1.3,3.5-0.8,2.1-2.1c-9-8.4-18.3-16.5-27.9-24.1
					C509,566.3,506.9,568.4,508.4,569.6L508.4,569.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M521.6,562.2c5.3,3,9.5,7.8,13.9,12c4.3,4.1,8.5,8.2,12.8,12.3c1.4,1.3,3.5-0.8,2.1-2.1
					c-4.7-4.5-9.4-9.1-14.1-13.6c-4.1-4-8.1-8.3-13.1-11.2C521.4,558.7,519.9,561.3,521.6,562.2L521.6,562.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M398,638.2c11.7,7.6,23.9,14.4,36.5,20.4c1.7,0.8,3.3-1.8,1.5-2.6c-12.6-6-24.7-12.9-36.5-20.4
					C397.9,634.6,396.4,637.2,398,638.2L398,638.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M529.5,557c0.4,0.1,0.8,0.2,1.2,0.3c0.8,0.2,1.7-0.2,1.8-1c0.2-0.8-0.2-1.6-1-1.8c-0.4-0.1-0.8-0.2-1.2-0.3
					c-0.8-0.2-1.7,0.2-1.8,1C528.2,555.9,528.7,556.8,529.5,557L529.5,557z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M529.2,556.8c12.6,5.6,23.5,14.2,35.7,20.5c1.7,0.9,3.2-1.7,1.5-2.6c-12.2-6.3-23.1-14.9-35.7-20.5
					C529,553.4,527.4,556,529.2,556.8L529.2,556.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M433.2,658.5c6.2,3.1,12.2,6.6,17.9,10.5c1.6,1.1,3.1-1.5,1.5-2.6c-5.8-3.9-11.7-7.4-17.9-10.5
					C433,655,431.5,657.6,433.2,658.5L433.2,658.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M447.6,650.1c4.6,3.4,9,7.1,13.2,11.1c1.4,1.3,3.5-0.8,2.1-2.1c-4.3-4.2-8.9-8-13.8-11.6
					C447.6,646.3,446.1,648.9,447.6,650.1L447.6,650.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M462,641c5,3.7,9.6,7.8,13.8,12.3c1.3,1.4,3.4-0.7,2.1-2.1c-4.4-4.7-9.2-9-14.4-12.8
					C462,637.3,460.5,639.9,462,641L462,641z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M477.1,631.4c4.2,4,8.2,8.2,12.1,12.4c1.3,1.4,3.4-0.7,2.1-2.1c-3.9-4.3-7.9-8.4-12.1-12.4
					C477.8,627.9,475.7,630,477.1,631.4L477.1,631.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M518.8,605c4,4.7,8,9.5,12.1,14.2c1.2,1.5,3.4-0.7,2.1-2.1c-4-4.7-8-9.5-12.1-14.2
					C519.6,601.4,517.5,603.6,518.8,605L518.8,605z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M536.5,594.4c4,4.2,7.8,8.6,11.6,13c1.3,1.5,3.4-0.7,2.1-2.1c-3.8-4.4-7.6-8.8-11.6-13
					C537.3,590.9,535.2,593,536.5,594.4L536.5,594.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M548.2,586.6c6.4,3,12.7,5.9,19.1,8.9c1.7,0.8,3.3-1.8,1.5-2.6c-6.4-3-12.7-5.9-19.1-8.9
					C548,583.2,546.4,585.8,548.2,586.6L548.2,586.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M564.6,576.5c5.6,3.5,11.3,6.8,17.1,9.8c1.7,0.9,3.2-1.7,1.5-2.6c-5.8-3-11.5-6.3-17.1-9.8
					C564.5,572.8,563,575.4,564.6,576.5L564.6,576.5z"
            />
          </g>
        </g>
        <path
          class="st4"
          d="M654.3,372.9c-1.2,11.1-2.2,22.5-2.8,34.4c-2.5,48.6,1.6,92.5,8.4,130.3c21.1-0.1,38.1-0.4,48.8-0.7
			c73.4-1.8,105.2-6.1,170.6-6.3c16.6,0,29.9,0.2,37.6,0.4c2.6-10.4,4.9-21.4,6.7-33c8.1-52.8,3-99.4-4.9-135.5
			c-13.6-2.6-33.7-5.7-58.3-7c-16.1-0.8-20.6-0.2-85.3,2.8c-46,2.1-58.3,2.5-79.7,6C678,366.9,663.8,370.3,654.3,372.9z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M1048.6,572.3c0.6,13.6,0.4,27.2-0.7,40.7c-0.1,1.9,2.9,1.9,3,0c1.1-13.6,1.3-27.1,0.7-40.7
					C1051.5,570.3,1048.5,570.3,1048.6,572.3L1048.6,572.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1066.2,575.2c0,12.6,0,25.2,0,37.8c0,1.9,3,1.9,3,0c0-12.6,0-25.2,0-37.8
					C1069.2,573.2,1066.2,573.2,1066.2,575.2L1066.2,575.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1048.3,611.6c-3.2-0.2-15.5,2-13,7.7c1.1,2.6,5.5,2.8,7.7,3.2c5.1,0.8,10.3,1,15.4,1
					c4.9-0.1,10-0.1,14.9-0.7c2.3-0.3,7-0.8,8.4-3.1c3.1-5.3-10.7-7.8-13.3-8.2c-1.9-0.3-2.7,2.6-0.8,2.9c2.2,0.4,4.3,0.8,6.5,1.6
					c2.9,1,5.7,1.6,2.1,3.3c-4,1.8-10.5,1.1-14.8,1.2c-5,0.1-10.1,0.1-15.1-0.5c-1.9-0.2-9.5-0.1-5.8-3.7c1.8-1.8,5.6-1.8,7.9-1.7
					C1050.3,614.7,1050.3,611.7,1048.3,611.6L1048.3,611.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M971.4,558.8c25.1,7.5,50.8,12.4,76.9,14.7c1.9,0.2,1.9-2.8,0-3c-25.8-2.2-51.3-7.1-76.1-14.5
					C970.4,555.4,969.6,558.3,971.4,558.8L971.4,558.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1066.5,575.5c33.4,5.9,67.4,8.1,101.1,4.7c1.9-0.2,1.9-3.2,0-3c-33.5,3.3-67.2,1.3-100.3-4.6
					C1065.4,572.3,1064.6,575.2,1066.5,575.5L1066.5,575.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M972.4,556.4c-2.4-43.4-4.6-87.3,5-130.1c0.4-1.9-2.5-2.7-2.9-0.8c-9.6,43-7.4,87.2-5.1,130.9
					C969.5,558.3,972.5,558.3,972.4,556.4L972.4,556.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M976.1,427.3c32-6.8,65.9-5.9,98.5-5.3c32.1,0.6,64.4,3.5,95.2,13.1c1.8,0.6,2.6-2.3,0.8-2.9
					c-31.1-9.7-63.6-12.6-96-13.2c-32.8-0.6-67-1.5-99.3,5.4C973.4,424.9,974.2,427.7,976.1,427.3L976.1,427.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M1169.9,434.4c7.2,47.7,5.9,96.5-3.7,143.7c-0.4,1.9,2.5,2.7,2.9,0.8c9.7-47.9,11-97.1,3.7-145.3
					C1172.5,431.7,1169.6,432.5,1169.9,434.4L1169.9,434.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M319.4,503.5c17.4,44.8,34.8,89.5,52.2,134.3c0.7,1.8,3.6,1,2.9-0.8c-17.4-44.8-34.8-89.5-52.2-134.3
					C321.6,500.9,318.7,501.7,319.4,503.5L319.4,503.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M371,638.8c34,18.5,68.8,35.5,104.2,51.1c1.8,0.8,3.3-1.8,1.5-2.6c-35.4-15.6-70.2-32.7-104.2-51.1
					C370.8,635.3,369.3,637.8,371,638.8L371,638.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M477.2,690.1c45.6-31.4,91.3-62.9,136.9-94.3c1.6-1.1,0.1-3.7-1.5-2.6c-45.6,31.4-91.3,62.9-136.9,94.3
					C474.1,688.6,475.6,691.2,477.2,690.1L477.2,690.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M320.7,503.2c52-25.9,105.6-48.5,160.5-67.7c1.8-0.6,1-3.5-0.8-2.9c-55.1,19.3-108.9,42-161.2,68
					C317.4,501.5,318.9,504,320.7,503.2L320.7,503.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M373.6,636.7c50.5-30.9,101.1-61.8,151.6-92.7c1.6-1,0.1-3.6-1.5-2.6c-50.5,30.9-101.1,61.8-151.6,92.7
					C370.5,635.1,372,637.7,373.6,636.7L373.6,636.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M479.2,435.1c14.7,35.5,29.3,71,44,106.4c0.7,1.8,3.6,1,2.9-0.8c-14.7-35.5-29.3-71-44-106.4
					C481.4,432.5,478.5,433.3,479.2,435.1L479.2,435.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M525.3,543.3c29.4,16.8,58.8,33.7,88.1,50.5c1.7,1,3.2-1.6,1.5-2.6c-29.4-16.8-58.8-33.7-88.1-50.5
					C525.1,539.8,523.6,542.4,525.3,543.3L525.3,543.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M651.5,373.6c-4.1,54.5,1.1,109.1,6.4,163.3c0.2,1.9,3.2,1.9,3,0c-5.3-54.3-10.5-108.8-6.4-163.3
					C654.7,371.7,651.7,371.7,651.5,373.6L651.5,373.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M653.9,374.9c31.8-11.6,66.1-12.4,99.5-14.4c34.7-2.1,69.5-4.8,104.2-3.8c20.4,0.6,40.7,2.5,60.8,6.6
					c1.9,0.4,2.7-2.5,0.8-2.9c-34.6-7.1-69.9-7.7-105-6.3c-35.4,1.4-71.1,3.1-106.3,6.5c-18.6,1.8-37.1,4.9-54.8,11.3
					C651.3,372.7,652.1,375.6,653.9,374.9L653.9,374.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M918.4,362c10.2,55.4,9.5,112.5-2.2,167.7c0.5-0.4,1-0.7,1.4-1.1c-66.8,2.2-133.5,4.3-200.3,6.5
					c-18.9,0.6-37.9,1.2-56.8,1.8c-1.9,0.1-1.9,3.1,0,3c66.8-2.2,133.5-4.3,200.3-6.5c18.9-0.6,37.9-1.2,56.8-1.8
					c0.6,0,1.3-0.4,1.4-1.1c11.7-55.7,12.5-113.3,2.2-169.3C920.9,359.3,918,360.1,918.4,362L918.4,362z"
            />
          </g>
        </g>
        <path
          d="M1050.3,571.9c0,5.6-0.1,11.3-0.2,17c-0.2,8-0.4,16-0.7,23.8c-1.3-0.2-4.1-0.4-7.2,0.9c-0.3,0.1-5.9,2.5-5.7,4.6
			c0.5,3.6,18.4,4.1,28.2,3.7c5.6-0.2,15.9-0.6,16.2-2.8c0.2-1.7-5-3.9-12.4-6.1c-0.3-13.2-0.6-26.5-0.9-39.7
			C1061.8,572.8,1056,572.3,1050.3,571.9z"
        />
        <g>
          <g>
            <path
              class="st2"
              d="M984.9,437.3c-3.9,32.9-5.1,66-3.5,99.1c0.1,1.9,3.1,1.9,3,0c-1.5-33.1-0.3-66.2,3.5-99.1
					C988.1,435.4,985.1,435.4,984.9,437.3L984.9,437.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M987.3,438.5c13.8-5.1,28.4-6.4,43-6.6c14.4-0.1,28.8,0.4,43.1,1.3c29.8,1.9,59.4,6,88.6,12
					c1.9,0.4,2.7-2.5,0.8-2.9c-29.5-6.1-59.3-10.2-89.4-12.1c-14.9-1-30-1.5-44.9-1.3c-14.2,0.2-28.5,1.7-41.9,6.7
					C984.7,436.2,985.5,439.1,987.3,438.5L987.3,438.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1161.8,444c1.5,39-2.1,78-6,116.8c-0.2,1.9,2.8,1.9,3,0c3.9-38.8,7.4-77.8,6-116.8
					C1164.7,442.1,1161.7,442.1,1161.8,444L1161.8,444z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M981.9,538.6c11.8,7,26.8,9.2,40,12.1c15.3,3.4,30.8,6.2,46.4,8.4c14.7,2.1,29.5,3.6,44.4,4.6
					c14.6,1,30.1,2.6,44.5-0.3c1.9-0.4,1.1-3.3-0.8-2.9c-14.4,3-30.1,1.1-44.7,0.2c-14.9-1-29.8-2.6-44.5-4.7
					c-14.3-2.1-28.5-4.6-42.6-7.7c-13.5-3-29.1-5.2-41.2-12.2C981.7,535,980.2,537.6,981.9,538.6L981.9,538.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M985.5,452c59.3-3.6,118.6,3,177.2,11.2c1.9,0.3,2.7-2.6,0.8-2.9c-58.9-8.3-118.4-14.9-178-11.3
					C983.6,449.1,983.6,452.1,985.5,452L985.5,452z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1005.8,442.1c-0.7-0.2-0.7-1.1,0-1.3c0.9-0.4,1.6,0.6,1.5,1.4c-0.1,1.3-1.6,0.4-1.4-0.3
					c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,0.1-0.2-0.1-0.2-0.1c0.1-0.2,0.7-0.7,0.7,0c0-1.9-3-1.9-3,0c0,1.5,1.2,2.6,2.7,2.6
					c1.6,0,2.8-1.4,2.5-3c-0.2-1.6-1.7-2.7-3.3-2.5c-1.7,0.3-2.9,1.9-2.8,3.6c0.3,3.7,5.7,5,7.1,1.3c1.2-3-1.4-6.5-4.7-5.8
					c-1.6,0.3-3,1.7-3,3.4c-0.1,1.7,1.1,3.3,2.7,3.8C1006.8,445.5,1007.6,442.6,1005.8,442.1L1005.8,442.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1021.8,440.2c-0.5-0.1,0-0.5,0.3-0.5c0.6,0.1,0.5,0.8,0,0.8c-0.1,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.3
					c0,0,0-0.2,0-0.3c0,0,0,0.1-0.1,0.1c-0.2,0,0.2-0.1,0.1,0c0.7-0.3-0.1,0.2,0.1,0c0,0,0-0.4,0-0.2c0,0,0.5,0.1,0,0
					c-0.8-0.3-1.6,0.3-1.8,1c-0.2,0.8,0.3,1.6,1,1.8c1.3,0.5,2.8-0.3,3.4-1.5c0.7-1.2,0.3-2.7-0.8-3.5c-2.1-1.6-5.2,0-5,2.7
					c0.3,2.9,3.7,4,5.7,1.9c2-2.2,0.3-5.6-2.6-5.7c-1.6,0-3.1,1.1-3.4,2.7c-0.4,1.6,0.7,3.3,2.3,3.7
					C1022.9,443.6,1023.7,440.7,1021.8,440.2L1021.8,440.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1051.7,479.6c-13.5,5.6-26.8,11.5-40,17.6c-1.3,0.6-0.7,2.4,0.4,2.7c11.3,4,22.1,9,32.5,14.9
					c1.7,1,3.2-1.6,1.5-2.6c-10.6-6.1-21.7-11.1-33.2-15.2c0.1,0.9,0.2,1.8,0.4,2.7c13-6,26.1-11.8,39.3-17.3
					C1054.3,481.7,1053.5,478.8,1051.7,479.6L1051.7,479.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1089.4,475.1c-11.3,14.3-22.5,28.5-33.8,42.8c-1.2,1.5,0.9,3.6,2.1,2.1c11.3-14.3,22.5-28.5,33.8-42.8
					C1092.7,475.8,1090.6,473.6,1089.4,475.1L1089.4,475.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1104.5,491.7c9.4,6,19.2,11.3,29.3,15.9c0-0.9,0-1.7,0-2.6c-10.8,6.1-21.6,12.2-32.4,18.2
					c-1.7,0.9-0.2,3.5,1.5,2.6c10.8-6.1,21.6-12.2,32.4-18.2c0.9-0.5,1-2.1,0-2.6c-10.1-4.6-19.9-9.9-29.3-15.9
					C1104.4,488,1102.9,490.6,1104.5,491.7L1104.5,491.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M1106.4,555.7c12.4,0.3,24.7,0.6,37.1,0.8c1.9,0,1.9-3,0-3c-12.4-0.3-24.7-0.6-37.1-0.8
					C1104.5,552.7,1104.5,555.7,1106.4,555.7L1106.4,555.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M329.2,506.2c15.4,36.7,29.8,73.8,43.4,111.2c0.7,1.8,3.6,1,2.9-0.8c-13.6-37.4-28-74.5-43.4-111.2
					C331.3,503.6,328.4,504.4,329.2,506.2L329.2,506.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M331,505.4c47.6-19.5,95.2-39.1,142.5-59.3c1.8-0.8,0.2-3.3-1.5-2.6c-47.1,20.1-94.4,39.6-141.8,59
					C328.4,503.3,329.2,506.2,331,505.4L331,505.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M473.2,445.3c14,29.9,27,60.2,39.1,90.8c0.7,1.8,3.6,1,2.9-0.8c-12.2-30.9-25.3-61.5-39.4-91.6
					C475,442.1,472.4,443.6,473.2,445.3L473.2,445.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M376.2,619.5c23.4-13.1,46.5-26.5,69.4-40.3c11.3-6.8,22.6-13.7,33.8-20.7c11-6.9,22-13.9,33.9-19.3
					c1.8-0.8,0.2-3.4-1.5-2.6c-11.9,5.4-22.9,12.4-33.9,19.3c-11.2,7-22.5,13.9-33.8,20.7c-22.9,13.8-46.1,27.2-69.4,40.3
					C373,617.9,374.5,620.5,376.2,619.5L376.2,619.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M387.8,528.6c-6.6-4-13.2,2.9-17,7.8c-4.6,5.9-5.8,13-3,20c2.5,6.1,8.1,12.2,15.2,11.8
					c8.4-0.5,13.4-7.9,17.2-14.5c1-1.7-1.6-3.2-2.6-1.5c-2.7,4.7-5.7,9.9-11,12c-6.3,2.6-11.9-1.2-15-6.7c-2.4-4.2-3.2-9.4-1.6-14.1
					c1.6-4.5,10.2-15.8,16.2-12.2C387.9,532.2,389.5,529.6,387.8,528.6L387.8,528.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M423.4,512.3c0-4.6-4.7-3.3-7.2-1.4c-4,3-8.8,9.8-9.5,14.8c-0.8,6.3,6.1,5.1,10.4,4.7
					c5.4-0.5,13,1.5,8.8,8.7c-2.7,4.6-8.2,6.7-13.2,6.9c-1.9,0.1-1.9,3.1,0,3c7.6-0.4,21.7-7.6,16.6-17.4c-2.2-4.1-7.1-4.7-11.3-4.4
					c-1.1,0.1-4.2,0.9-5.3,0.5c-4-1.5-1.8-4.7-0.8-6.6c0.7-1.3,1.4-2.7,2.3-3.9c0.3-0.4,6.1-6.2,6.1-5
					C420.4,514.3,423.4,514.3,423.4,512.3L423.4,512.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st1"
              d="M461.6,489.7c-4.9-1.9-9.3,2.3-12.2,5.8c-2.4,2.8-6.7,8.5-5.2,12.6c1.6,4.4,8.4,2.4,11.6,2
					c1.7-0.2,3.6-0.5,5.3-0.2c4.5,0.6,6.1,5.2,4,8.9c-2.5,4.4-7.4,5.8-12,6.4c-1.9,0.3-1.1,3.2,0.8,2.9c7.4-1.1,20.4-8.6,14-17.9
					c-3.1-4.5-9.3-3.4-13.8-2.9c-1,0.1-4.7,1.1-5.3,0.4c-1.5-1.5-0.6-5,0.3-6.6c2-3.4,6.9-10.5,11.8-8.6
					C462.6,493.3,463.4,490.4,461.6,489.7L461.6,489.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M464,556.4c7.5-4.8,15.1-9.7,22.6-14.5c1.6-1,0.1-3.6-1.5-2.6c-7.5,4.8-15.1,9.7-22.6,14.5
					C460.9,554.9,462.4,557.5,464,556.4L464,556.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M493,538.6c1.9-0.9,3.8-1.8,5.8-2.7c1.7-0.8,0.2-3.4-1.5-2.6c-1.9,0.9-3.8,1.8-5.8,2.7
					C489.7,536.8,491.3,539.4,493,538.6L493,538.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M446.9,463.6C446.9,463.6,446.9,463.6,446.9,463.6c0.1-0.2,0.1-0.4,0.2-0.7c0-0.1,0.1-0.1,0.1-0.2
					c0.2-0.1,0.4-0.3,0.7-0.4c-0.3,0.1,0.6,0.1,0.7,0.1c0.3,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0-0.2,0,0c-0.1,0.3,0-0.2,0,0
					c0,0.1,0,0.1,0,0c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.1-0.1,0,0c-0.1,0-0.2,0-0.1,0c0.1,0,0.1,0,0,0
					c0-0.1,0,0.1,0,0c0,0-0.1,0-0.1-0.1c-0.1-0.1,0-0.1,0,0.1c0-0.1,0-0.1,0,0c0,0-0.1-0.3,0-0.3c0,0-0.1,0.2-0.1,0.3
					c0.1-0.3-0.1,0.1,0-0.1c0,0,0.2-0.2,0.1-0.1c-0.1,0.1,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0.1c0.1,0,0.1,0.1,0,0c0,0,0.1,0.3,0.1,0.1
					c0-0.2,0,0.3,0,0.4c-0.1,0.3-0.4,0.4-0.6,0.4c0.2,0,0,0-0.1,0c0.2,0.1,0,0-0.1,0c0.2,0.1-0.1-0.1-0.1-0.1
					c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.3,0-0.4,0.3-0.6c0.2-0.2,0.2-0.2,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c-0.2,0,0.1,0,0.1,0
					c-0.2,0,0.1,0,0.1,0c-0.2-0.1,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.5,0.4,0.7c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5
					c0,2.8,4.5,2.8,4.6,0.1c0.1-2.7-4.2-3.2-4.8-0.6c-0.3,1.3,0.6,2.7,1.9,3.1c1.2,0.3,2.5-0.3,3-1.4c0.6-1.1,0.4-2.5-0.5-3.4
					c-0.9-0.9-2.4-1.2-3.5-0.5c-1.2,0.7-1.6,2.4-0.9,3.6c0.4,0.6,1.1,1.1,1.8,1.1c0.9,0,1.8-0.8,1.8-1.8c-0.1-0.8-0.6-1.5-1.5-1.5
					C447.7,462.1,446.9,462.8,446.9,463.6L446.9,463.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M460.5,457.6c0.8-0.2,1.1,1.1,0.3,1.2c-0.1,0-0.4-0.1-0.3-0.1c0.1,0.1-0.2-0.2,0,0c-0.2-0.2-0.1-0.1,0,0.1
					c0-0.1,0-0.1-0.1-0.2c0,0.1,0,0.1,0,0c0-0.1,0-0.1,0,0c0,0.1,0,0.1,0-0.1c0-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.2
					c-0.4,0,0.3,0,0,0c0.1,0,0.1,0,0.2,0.1c-0.1-0.1-0.1-0.1,0,0c0.1,0.1,0.1,0.1,0,0c0,0,0.1,0.1,0.1,0.1c-0.1-0.3,0.1,0.3,0,0
					c0,0.1,0,0.5,0.1,0.3c-0.3,0.3-0.7,0.7-1,1c0.2-0.1,0.5-0.2,0.7-0.2c-0.2,0,0.2,0,0,0c-0.1,0-0.1,0,0,0c0.1,0,0.1,0,0,0
					c-0.1-0.1-0.2-0.1-0.3-0.3c0-0.1-0.1-0.4-0.1-0.3c0,0.2,0.1-0.3,0-0.1c0.1-0.4,0.5-0.7,0.9-0.7c-0.8,0-1.5,0.7-1.5,1.5
					c0,0.8,0.7,1.5,1.5,1.5c2.3,0,2.8-3.2,0.9-4.3c-0.9-0.5-2-0.4-3,0c-1.2,0.5-1.4,1.9-0.8,3c1,1.8,3.9,2,4.6-0.2
					c0.6-1.8-1.1-3.8-3-3.5c-1.3,0.2-2.2,1.7-1.9,2.9c0.3,1.3,1.7,2.1,2.9,1.7c0.8-0.2,1.3-1.1,1-1.8
					C462.1,457.9,461.3,457.4,460.5,457.6L460.5,457.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M660.4,378.9c0.8,50.1,3.4,100.1,8,150c0.2,1.9,3.2,1.9,3,0c-4.5-49.9-7.2-99.9-8-150
					C663.4,376.9,660.4,376.9,660.4,378.9L660.4,378.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M663.9,380.5c20.5-3.6,41.3-4.3,62-5.2c20.1-0.8,40.3-1.6,60.4-2.3c40.6-1.5,81.2-2.7,121.8-3.8
					c1.9,0,1.9-3,0-3c-41.3,1.1-82.5,2.3-123.8,3.8c-20.5,0.7-40.9,1.5-61.4,2.4c-20,0.8-40,1.7-59.8,5.2
					C661.3,377.9,662.1,380.8,663.9,380.5L663.9,380.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M907.6,368.5c5.6,10.7,5.3,23.1,5.6,34.8c0.3,12.2,0.4,24.4,0.3,36.6c-0.2,24.4-1.1,48.7-2.8,73.1
					c-0.1,1.9,2.9,1.9,3,0c1.7-25.6,2.7-51.3,2.8-77c0.1-12.5-0.1-25-0.4-37.6c-0.3-10.8-0.7-21.6-5.9-31.4
					C909.3,365.3,906.7,366.8,907.6,368.5L907.6,368.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M670.6,529.3c78.8-14.6,159.8-12.7,239.5-14c1.9,0,1.9-3,0-3c-80.1,1.3-161.3-0.5-240.3,14.1
					C667.9,526.8,668.7,529.7,670.6,529.3L670.6,529.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M690.5,387.4c-4,2.7-7.8,5.7-11.4,9c-0.7,0.7-0.5,1.9,0.3,2.4c3.6,2.2,8.9,1.7,11.7,5
					c1.2,1.5,3.3-0.6,2.1-2.1c-3.1-3.8-8.4-3.1-12.3-5.5c0.1,0.8,0.2,1.6,0.3,2.4c3.4-3.1,7-5.9,10.8-8.5
					C693.6,388.9,692.1,386.3,690.5,387.4L690.5,387.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M707,385.9c-4.1,6.1-7.5,12.4-10.4,19.1c-0.8,1.8,1.8,3.3,2.6,1.5c2.9-6.7,6.3-13.1,10.4-19.1
					C710.6,385.8,708,384.3,707,385.9L707,385.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M712.9,397.6c4.6-0.6,9.3-0.7,13.9-0.3c1.9,0.2,1.9-2.8,0-3c-4.7-0.4-9.3-0.3-13.9,0.3
					C711,394.8,710.9,397.8,712.9,397.6L712.9,397.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M730.2,386.8c3.6,5,10.2,5.4,14.3,9.7c0.1-0.8,0.2-1.6,0.3-2.4c-4.4,2.5-8.4,5.4-12,8.8
					c-1.4,1.3,0.7,3.4,2.1,2.1c3.5-3.2,7.3-6,11.4-8.3c0.9-0.5,1-1.7,0.3-2.4c-3.9-4-10.6-4.5-13.9-9.1
					C731.6,383.8,729,385.3,730.2,386.8L730.2,386.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M669.9,423.6c4.1-0.3,8.2-0.6,12.3-0.9c1.9-0.1,1.9-3.1,0-3c-4.1,0.3-8.2,0.6-12.3,0.9
					C668,420.8,668,423.8,669.9,423.6L669.9,423.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M691.8,422c27.7-1.6,55.4-3.3,83.1-4.9c1.9-0.1,1.9-3.1,0-3c-27.7,1.6-55.4,3.3-83.1,4.9
					C689.9,419.1,689.8,422.1,691.8,422L691.8,422z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M671.5,435.6c2.3,0,4.5,0,6.8,0c1.9,0,1.9-3,0-3c-2.3,0-4.5,0-6.8,0C669.5,432.6,669.5,435.6,671.5,435.6
					L671.5,435.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M686.6,434.4c2.7,0.2,5.4-0.1,8-0.8c1.9-0.5,1.1-3.4-0.8-2.9c-2.4,0.6-4.7,0.8-7.2,0.6
					C684.7,431.3,684.7,434.3,686.6,434.4L686.6,434.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M711.2,432.3c26.3-2.5,52.7-3.2,79.2-2.1c1.9,0.1,1.9-2.9,0-3c-26.4-1.1-52.8-0.4-79.2,2.1
					C709.3,429.4,709.3,432.4,711.2,432.3L711.2,432.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M675.2,450.6c-1.7,2.1-3.5,4.2-5.5,6.1c-0.8,0.8-0.4,2.2,0.7,2.5c3.1,0.9,6.3,1.3,9.5,1.4c1.9,0,1.9-3,0-3
					c-3,0-5.8-0.4-8.7-1.3c0.2,0.8,0.4,1.7,0.7,2.5c2-1.9,3.8-3.9,5.5-6.1C678.5,451.2,676.3,449,675.2,450.6L675.2,450.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M685.4,456.5c13.6-2.6,27.4-3.8,41.3-3.6c1.9,0,1.9-3,0-3c-14.2-0.3-28.2,1-42.1,3.7
					C682.8,454,683.6,456.8,685.4,456.5L685.4,456.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M730.8,449c4.9,2.5,10.8,0.8,15.5,3.5c0.1-0.9,0.2-1.8,0.4-2.7c-2.8,0.9-5.3,2.2-7.7,3.9
					c-1.6,1.1-0.1,3.7,1.5,2.6c2.2-1.5,4.4-2.7,7-3.6c1.2-0.4,1.5-2.1,0.4-2.7c-4.8-2.9-10.6-1-15.5-3.5
					C730.6,445.5,729.1,448.1,730.8,449L730.8,449z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M863.2,378.8c-2.4,0.4-3.9,2-4.4,4.4c-0.3,1.6,0.2,3.2,0.5,4.7c1,4.4,0.4,6.6-3.6,9
					c-1.2,0.8-0.8,2.8,0.8,2.8c4.2-0.1,2.7,19.3,11.6,12.8c1.6-1.1,0.1-3.7-1.5-2.6c-4.1,2.9-3.8-6.6-4-7.6c-0.2-1.1-0.5-2.1-1-3
					c-1.2-1.9-2.8-2.7-5.1-2.6c0.3,0.9,0.5,1.9,0.8,2.8c2.3-1.4,4.7-3,5.6-5.7c0.8-2.4-2.6-11.4,1.2-12.1
					C865.9,381.4,865.1,378.5,863.2,378.8L863.2,378.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M882.7,380c3.4-0.5,4.7,1.5,4.1,6.2c-0.3,0.6-0.6,1.1-0.9,1.7c-0.2,1-0.6,1.7-0.6,2.8
					c0,2.1,1.4,2.5,2.1,4.1c0.6,1.5-0.1,1.3-0.3,3.2c-0.6,4,4.9,7.3-0.3,10.9c-1.6,1.1-0.1,3.7,1.5,2.6c2.2-1.5,3.8-3.7,3.8-6.5
					c0-1.1-0.5-2.1-0.8-3.1c-0.6-2.5-0.2-4.3,0.5-6.7c0.1-0.5,0-1.1-0.4-1.5c-0.3-0.2-1.1-0.8-1.4-1.1c-2.4-2.4-0.8-3.6,0.3-6.3
					c0.6-1.6,1.1-2.9,0.6-4.6c-0.9-3.6-4.7-4.8-8-4.7C880.8,377,880.8,380,882.7,380L882.7,380z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M773.3,396c15.8-1.3,31.5-2.5,47.3-3.8c1.9-0.2,1.9-3.2,0-3c-15.8,1.3-31.5,2.5-47.3,3.8
					C771.4,393.1,771.4,396.1,773.3,396L773.3,396z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M841.3,391.2c3.6,0.5,7.2,0.4,10.7-0.2c1.9-0.3,1.1-3.2-0.8-2.9c-3.3,0.5-6.6,0.5-9.9,0.1
					C839.4,387.9,839.4,390.9,841.3,391.2L841.3,391.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M681,477.3c26.5-1.7,53-3.5,79.5-5.2c1.9-0.1,1.9-3.1,0-3c-26.5,1.7-53,3.5-79.5,5.2
					C679.1,474.4,679.1,477.4,681,477.3L681,477.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M777.7,470c10.1-1,20.1-1.9,30.2-2.8c1.9-0.2,1.9-3.2,0-3c-10.1,0.8-20.2,1.8-30.2,2.8
					C775.8,467.2,775.8,470.2,777.7,470L777.7,470z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M830.6,466.1c19.7-2,39.5-3.5,59.2-4.4c1.9-0.1,1.9-3.1,0-3c-19.8,1-39.5,2.4-59.2,4.4
					C828.7,463.3,828.7,466.3,830.6,466.1L830.6,466.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M692.8,487.2c-3.7,2.6-7.5,5.3-11.2,7.9c-1,0.7-1,2.4,0.4,2.7c3.9,1,7.8,1.8,11.8,2.4
					c1.9,0.3,2.7-2.6,0.8-2.9c-4-0.6-7.9-1.4-11.8-2.4c0.1,0.9,0.2,1.8,0.4,2.7c3.7-2.6,7.5-5.3,11.2-7.9
					C695.9,488.6,694.4,486,692.8,487.2L692.8,487.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M708.1,485.5c-4.3,5-8.3,10.2-11.9,15.8c-1.1,1.6,1.5,3.1,2.6,1.5c3.4-5.3,7.2-10.4,11.4-15.2
					C711.5,486.2,709.4,484,708.1,485.5L708.1,485.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M717,489.8c1.3,1,2.7,1.5,4.1,2c0.9,0.3,1.8,0.5,2.7,0.8c0.5,0.2,1.1,0.5,1.5,0.8c-0.1-0.7-0.4-0.9-0.9-0.6
					c-1.3-0.1-3.8,1.5-5,2.1c-1.9,0.9-3.6,2.1-5.3,3.3c-1.5,1.1,0,3.7,1.5,2.6c2.2-1.7,4.6-3.1,7.2-4.2c1.6-0.7,5-1,5.5-3.1
					c0.4-1.8-1.6-2.8-2.9-3.4c-2.3-1-4.9-1.3-7-2.8C717,486.1,715.5,488.7,717,489.8L717,489.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M750.3,494c12.3-1,24.6-2.3,36.9-1.6c1.9,0.1,1.9-2.9,0-3c-12.4-0.7-24.6,0.6-36.9,1.6
					C748.3,491.1,748.3,494.1,750.3,494L750.3,494z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st2"
              d="M813.9,489.5c23.1-2.7,46.3-3.7,69.6-3.2c1.9,0,1.9-3,0-3c-23.2-0.5-46.5,0.6-69.6,3.2
					C812,486.7,812,489.7,813.9,489.5L813.9,489.5z"
            />
          </g>
        </g>
        <path
          class="st7"
          d="M985.5,436.1c-0.5,5-1,10.1-1.4,15.1c28.9-0.9,60.6-0.6,94.6,1.6c30.1,2,58.2,5.2,83.9,9.2
			c0.6-6.3,1.1-12.6,1.7-18.9c-11.6-2.3-26.9-5-44.9-7.5c-23.1-3.2-40.7-4.6-50.3-5.3c-24.3-1.7-36.7-2.5-53.2-0.5
			C1003,431.5,992.6,434.1,985.5,436.1z"
        />
        <path
          class="st8"
          d="M379.7,474.1c-3.3,9-7.3,22.1-9.2,38.2c-2.3,19.9-8.2,70.7,18.4,86.2c18.2,10.6,47.2,2.2,71.7-17.8
			c4.2,3.4,8.5,6.7,12.7,10.1c-24.9,15.1-49.8,30.2-74.7,45.3c17.6,10.5,35.2,20.9,52.7,31.4c19-11.3,37.9-22.5,56.9-33.8
			c9.6,4.7,19.2,9.5,28.7,14.2c-19.9,14-39.7,28-59.6,42.1c-34.7-17.3-69.3-34.6-104-51.9c-17.6-45.7-35.2-91.5-52.7-137.2
			C340.4,492.1,360.1,483.1,379.7,474.1z"
        />
        <path class="st9" d="M484.9,633.5" />
        <path
          class="st10"
          d="M1106,422.6c2.4,5.7,5.3,13.9,7.1,24.1c0.7,4.2,6.2,37.5-8.5,66.8c-15.7,31.4-51.2,51.6-91.2,53.9
			c30.5,6.6,66.2,11.8,106.2,12.9c16.8,0.5,32.8,0.2,47.8-0.7c4.2-17.2,7.8-37.7,9.2-61.1c2-33.8-1.2-63-5.4-85.5
			C1149.4,429.7,1127.7,426.1,1106,422.6z"
        />
        <path
          class="st5"
          d="M858,355.8c3.5,6.5,7.9,16.3,9.7,28.8c4.5,30.2-9,54.3-18.3,70.5c-28,48.5-74.7,71.8-95.5,80.7
			c43.4-0.3,88-1.6,133.6-4.3c10.2-0.6,20.3-1.2,30.4-1.9c4.4-19.4,8-42.3,9.4-68.2c2-39.6-2.1-73.8-7.4-100.1
			C899.3,359.4,878.7,357.6,858,355.8z"
        />
      </g>
    </g>
    <g id="guy__x26__chair">
      <g id="leg_right">
        <path
          d="M830,992.2c1,6.8,1.9,13.9,2.7,21.3c0.9,8.5,1.4,16.7,1.8,24.5c0.8,1.5,2.2,3.5,4.5,5.1c3.6,2.5,7.5,2.5,11.7,2.4
			c12.2-0.3,18.3-0.4,21-0.9c9.5-1.9,18.5-3.6,20.4-9.6c1.3-4-1.3-8.4-1.5-8.7c-1.5-2.5-3.5-3.6-6.9-5.4c-2.3-1.3-6.2-3.3-11.7-3.9
			c-4.7-0.5-8.6,0.2-11.1,0.9c-1.3-10.6-2.6-21.2-3.9-31.7C848,988.2,839,990.2,830,992.2z"
        />
        <path
          class="st4"
          d="M811.9,794.3c-8.1,8.8-5.6,18.2-8.9,74.9c-1.3,22.8-1.8,21.7-2.2,35.4c-1.1,31.3,0.6,52.9,1.6,63.4
			c0.7,7.9,1.6,14.5,2.2,19.1c5.9,3,16,7,28.4,5.7c7.3-0.7,12.8-3.1,16.9-4.8c9-3.8,15.6-8.8,19.8-12.4
			c-1.7-63.1-3.4-126.2-5.1-189.3C829.3,779.6,816.6,789.2,811.9,794.3z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M805,802.6c-5.6,60.7-6.3,121.8-2.1,182.7c0.1,1.9,3.1,1.9,3,0c-4.2-60.8-3.5-122,2.1-182.7
					C808.2,800.7,805.2,800.7,805,802.6L805,802.6z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M804.4,988.2c22.2,11.5,47.7,4.9,65.5-11.4c1.4-1.3-0.7-3.4-2.1-2.1c-16.8,15.4-40.9,21.8-61.9,11
					C804.2,984.7,802.7,987.3,804.4,988.2L804.4,988.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M862.3,788.9c1.6,61.8,3.2,123.6,4.8,185.4c0.1,1.9,3.1,1.9,3,0c-1.6-61.8-3.2-123.6-4.8-185.4
					C865.3,787,862.3,787,862.3,788.9L862.3,788.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M828.7,993.5c2.4,12.1,3.9,24.3,4.5,36.6c0.1,1.9,3.1,1.9,3,0c-0.6-12.6-2.1-25.1-4.6-37.4
					C831.2,990.8,828.3,991.6,828.7,993.5L828.7,993.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M855.5,986c0.6,13.1,1.8,26.1,3.6,39.1c0.3,1.9,3.2,1.1,2.9-0.8c-1.8-12.7-3-25.5-3.5-38.3
					C858.4,984.1,855.4,984.1,855.5,986L855.5,986z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M860.6,1018.5c10.8-1.4,21.4,1.8,29.5,9.1c1.4,1.3,3.6-0.8,2.1-2.1c-8.7-7.8-20-11.5-31.6-10
					C858.7,1015.7,858.7,1018.7,860.6,1018.5L860.6,1018.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M833.2,1029c-0.5,6.3-0.2,13.9,6.5,16.8c2.6,1.1,5.9,1.2,8.7,1.4c4.8,0.4,9.6,0.6,14.4,0.4
					c11.5-0.6,33.8-5.2,30.5-21.3c-0.4-1.9-3.3-1.1-2.9,0.8c2.9,14.2-19.9,17.3-29.4,17.6c-5.1,0.2-10.2-0.1-15.3-0.7
					c-9-1.1-10.1-7-9.5-14.9C836.3,1027.1,833.3,1027.1,833.2,1029L833.2,1029z"
            />
          </g>
        </g>
        <path
          class="st6"
          d="M807,802c9.8-3.5,19.6-7,29.4-10.4c-1.7,32.5-2.1,68-0.4,106c1.4,32.4,4.2,62.8,7.7,90.9
			c-2,0.9-4.2,1.7-6.6,2.3c-13.8,3.6-26.1-1-32.1-3.9c-2.8-33.3-4.2-70.1-3.1-109.9C802.7,850.5,804.5,825.5,807,802z"
        />
        <path
          class="st11"
          d="M806.2,804c0.4,17.5,1.5,40.2,3.9,66.5c1.9,20.9,4,36.5,4.6,41.4c2.9,21.1,7.3,48.5,14.7,80.5
			c-8.4-1.9-16.8-3.9-25.1-5.8c-1.5-32.1-2.2-66.1-1.5-101.8C803.2,856.7,804.4,829.7,806.2,804z"
        />
      </g>
      <g id="chair">
        <path
          d="M784.9,876.6c0.6,3.1,1.4,7.6,2.4,13.2c5.9,35.6,5.8,61.2,6.4,82.8c0.5,18.4,1.7,44.7,5.1,77.4c-2.1,0.9-5.3,2.4-9.1,4.4
			c-9.4,4.9-22,11.5-33.8,23.7c-3.5,3.6-8.4,9.2-13.2,16.9c15.9,1.5,34.7,2.2,55.8,0.7c25.9-1.9,48.3-6.5,66.2-11.5
			c-5.4-4.6-11.4-9.2-17.9-13.9c-12.4-8.7-24.3-15.6-35.1-21c-2.4-23.3-4.3-47.8-5.4-73.3c-1.7-39.4-1.5-76.5,0-110.9
			C799.1,869,792,872.8,784.9,876.6z"
        />
        <path
          class="st4"
          d="M710.9,828c0-4.6,5.3-8.5,15.9-16.2c5-3.7,18.8-13,41.9-18.3c20.3-4.6,35.9-3.3,54.8-1.7
			c26.9,2.3,39.3,7.7,42.6,9.1c9.8,4.4,17.2,9.6,22,13.5c-1.7,6.1-5.1,14.7-12.2,22.6c-4.9,5.6-18.3,18.4-55.4,21.3
			C769,862.5,710.8,844.7,710.9,828z"
        />
        <path
          d="M713.9,822.2c-1.3,2.8-2.5,5.9-3.4,9.5c-3.4,12.9-1.5,24.2,0.3,31.1c5.4,2.5,13.1,5.6,22.6,8.4c29.2,8.7,53,7.5,72.3,6.4
			c25.4-1.4,41.8-2.6,60.8-12.5c10.5-5.5,18.3-11.9,23.3-16.6c-0.3-10.9-0.7-21.9-1-32.8c-8.3,5.5-17.8,10.7-28.4,14.9
			C798.4,855.4,737,832.4,713.9,822.2z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M709.2,829c-2.1,11.3-2,22.7,0.1,34c0.4,1.9,3.2,1.1,2.9-0.8c-2-10.8-2.1-21.6-0.1-32.4
					C712.5,827.9,709.6,827.1,709.2,829L709.2,829z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M710.2,863c13.5,8.7,29.8,10.9,45.4,13.2c16.8,2.4,33.7,3.8,50.7,2.4c29.1-2.4,65-5.2,84.9-29.5
					c1.2-1.5-0.9-3.6-2.1-2.1c-9.4,11.5-22.9,18.1-37.1,21.8c-15.4,4-31.7,5.8-47.6,6.9c-16.7,1.2-33.4-0.3-49.9-2.7
					c-14.7-2.2-30.1-4.3-42.8-12.6C710.1,859.4,708.6,862,710.2,863L710.2,863z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M887,816.2c1.9,10.4,2.5,20.8,1.7,31.3c-0.1,1.9,2.9,1.9,3,0c0.8-10.8,0.1-21.5-1.8-32.1
					C889.6,813.5,886.7,814.3,887,816.2L887,816.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M712,829.3c15.9-27.4,51.5-35.4,80.9-37.1c17.4-1,34.9,0.4,51.9,4.4c8.1,1.9,16.2,4.2,24,7.3
					c7.4,2.9,14.6,6.8,18.3,14.2c0.9,1.7,3.5,0.2,2.6-1.5c-7.1-14-26.6-18.5-40.5-22c-18.3-4.6-37.4-6.4-56.2-5.3
					c-30.4,1.8-66.9,10.2-83.4,38.6C708.4,829.4,711,830.9,712,829.3L712,829.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M784.6,877.7c6.3,56.9,10.5,114,12.7,171.2c0.1,1.9,3.1,1.9,3,0c-2.1-57.2-6.3-114.3-12.7-171.2
					C787.4,875.8,784.4,875.8,784.6,877.7L784.6,877.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M803.6,877.7c0.2,57.5,2.1,114.9,5.8,172.3c0.1,1.9,3.1,1.9,3,0c-3.7-57.4-5.6-114.8-5.8-172.3
					C806.5,875.8,803.5,875.8,803.6,877.7L803.6,877.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M797.1,1048c-22.4,10.3-41.6,26.2-56.2,46.1c-0.5,0.7,0,2.1,0.9,2.2c20.7,3.4,41.6,1.7,62.3-0.6
					c20.2-2.2,40.6-4.4,60.1-10.2c1-0.3,1.6-1.8,0.7-2.5c-16.2-13.6-33.9-25-52.9-34.2c-1.7-0.8-3.3,1.8-1.5,2.6
					c18.8,9,36.3,20.3,52.3,33.7c0.2-0.8,0.4-1.7,0.7-2.5c-19.3,5.8-39.4,7.9-59.3,10.1c-20.4,2.3-41.1,4-61.5,0.7
					c0.3,0.7,0.6,1.5,0.9,2.2c14.2-19.5,33.2-34.9,55.1-45C800.3,1049.8,798.8,1047.2,797.1,1048L797.1,1048z"
            />
          </g>
        </g>
      </g>
      <g id="leg_left">
        <path
          d="M694.6,783.1c2.3,9.1,4.7,18.2,7,27.3c-12.4-4-23.1-6.1-30.9-7.3c-5.6-0.8-29.3-4.4-30.9,0.6c-0.6,1.9,2.2,4.8,2.5,5.1
			c2.3,2.3,5.1,2.5,7.6,3.1c5.1,1.1,6.8,3,12.1,5.6c8,4,25.3,10.3,44.7,13.8c6.6,1.2,15.2,2.4,25.6,2.8
			c-4.1-17.9-8.3-35.8-12.4-53.7C711.5,781.2,703,782.1,694.6,783.1z"
        />
        <path
          class="st4"
          d="M690.7,532.6c-4.5,1.8-8,5.4-13.6,16.3c-9,17.4-11.8,29.8-11.8,29.8c-8.4,37.3-12.5,56-14,70.5
			c-4.6,46,3,48,2.7,96.3c0,3.6-0.1,11.4,4.5,19c7.3,11.9,21.4,15.3,25.3,16.3c11.5,2.8,22.4,0.8,31.7-0.9c2.6-0.5,4.8-1,6.3-1.4
			c7.2,5.7,13.8,9.6,18.5,12.2c9.1,4.9,17,9.2,28,10.4c6.8,0.8,13.4,0.2,16.3,0c5-0.4,9.1-1.2,12.2-1.8c4.7,1.6,11.9,3.4,20.8,3.6
			c5.6,0.1,24,0.2,40.2-11.8c14.5-10.7,19.5-25.1,23.1-35.7c3.4-10.2,16.4-48.9-3.2-69.6c-17-18-49.3-12.8-75.1-8.6
			c-12.4,2-22.6,5.1-29.8,7.7c-11.9-30.8-20.7-55.1-26.7-71.9c-5.7-15.9-14-30.9-19-47c-4.5-14.4-6.9-25.9-17.2-31.7
			C709.2,533.9,699.8,528.9,690.7,532.6z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M794.8,801.7c26.5,6.1,56.9,2.1,75.1-20.1c9.1-11.1,12-24.9,14.5-38.7c2.5-14.3,3.9-28.9-0.7-42.9
					c-0.6-1.8-3.5-1-2.9,0.8c4.5,13.5,3.2,27.6,0.7,41.3c-2.3,13.2-5,26.7-13.7,37.4c-17.4,21.4-46.7,25.1-72.2,19.3
					C793.7,798.3,792.9,801.2,794.8,801.7L794.8,801.7z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M735.1,585c-1.4-13.4-4.9-27.2-11.6-39c-5-8.7-13-17-23.8-16.5c-11.4,0.5-19.8,9.7-24.5,19.2
					c-6.4,12.6-10.4,26.9-14.1,40.4c-7.6,27.7-11.9,56.4-12.8,85.1c-0.5,15.2-0.1,30.5,1.2,45.7c1.2,13.7,2.2,28.6,6.1,41.8
					c3.3,11.3,18.4,18,28.9,20.7c12.6,3.2,25.7,2.1,37.5-3.1c1.8-0.8,0.2-3.4-1.5-2.6c-10.6,4.7-22.5,5.7-33.8,3.2
					c-9.9-2.2-25-8.3-28.2-18.9c-1.7-5.7-2.3-11.9-3.2-17.8c-1.1-7.1-1.9-14.2-2.6-21.4c-1.1-12.1-1.7-24.3-1.6-36.5
					c0.1-25.5,2.8-50.9,8.2-75.8c2.6-11.9,5.7-23.7,9.5-35.3c3.8-11.6,7.4-25.3,16.1-34.3c4.9-5,11.9-8.5,19-7.2
					c6.8,1.3,12,6.6,15.6,12.2c7.6,11.6,11.3,26.3,12.8,40C732.3,586.9,735.3,587,735.1,585L735.1,585z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M682.9,614.8c-3.4,36.5,0.4,73.6,11.2,108.7c5.2,16.8,11.3,35.4,21.9,49.7c9.4,12.7,24.1,21.1,39,25.8
					c17.2,5.5,36.1,5.8,52.4-2.5c1.7-0.9,0.2-3.5-1.5-2.6c-14.9,7.6-32,7.6-47.7,3c-16.1-4.7-32.2-13.9-41.6-28.1
					c-9.2-14-15-30.9-19.8-46.9c-5-16.3-8.5-33.1-10.4-50.1c-2.1-18.9-2.2-38-0.5-56.9C686.1,612.9,683.1,612.9,682.9,614.8
					L682.9,614.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M676.4,604.2c0.6,13.1,2.5,26,5.8,38.7c0.5,1.9,3.4,1.1,2.9-0.8c-3.3-12.4-5.2-25.1-5.7-37.9
					C679.3,602.3,676.3,602.3,676.4,604.2L676.4,604.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M733,585.2c18.9,45.1,35.6,91.2,50.1,138c0.6,1.8,3.5,1.1,2.9-0.8c-14.4-46.8-31.1-92.8-50.1-138
					C735.1,582.7,732.2,583.5,733,585.2L733,585.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M692.8,784.5c2.6,9,5.2,18,7.8,27.1c0.5,1.9,3.4,1.1,2.9-0.8c-2.6-9-5.2-18-7.8-27.1
					C695.1,781.9,692.2,782.7,692.8,784.5L692.8,784.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M701.8,809.8c-20-7.9-41.5-10.6-62.9-7.8c-0.8,0.1-1.9,0.9-1.4,1.9c2.6,6.4,9.9,8.3,15.7,10.8
					c8.3,3.6,16.6,7.1,25.1,10.1c17,6.1,34.7,10.3,52.8,9.8c1.9,0,1.9-3,0-3c-16,0.4-31.7-2.8-46.8-7.9c-7.3-2.4-14.4-5.2-21.5-8.2
					c-3.8-1.6-7.7-3.2-11.5-4.9c-3.9-1.7-9.2-3.3-11-7.6c-0.5,0.6-1,1.3-1.4,1.9c21.1-2.8,42.3-0.1,62.1,7.7
					C702.8,813.4,703.5,810.5,701.8,809.8L701.8,809.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M718,779.1c2.1,18.5,10.9,35.5,12.2,54.1c0.1,1.9,3.1,1.9,3,0c-1.3-18.6-10.1-35.6-12.2-54.1
					C720.8,777.2,717.8,777.2,718,779.1L718,779.1z"
            />
          </g>
        </g>
        <path
          class="st11"
          d="M688.6,780c-3.4-8.7-8.1-21.7-12.6-37.7c-1.9-6.9-14.1-52-11.5-110.4c0.7-15.3,2.4-32.2,14.6-44.5
			c4.7-4.7,10-10,14.6-8.9c19.2,4.5-15.3,106.5,33,161.6c24.8,28.3,60.6,31.9,66.4,32.4c51.4,4.6,86.1-32.3,90.5-37.1
			c0.3,6.1-0.1,15.6-4.2,26.2c-7.4,19.2-21.8,29.1-27.2,32.4c-24.9,15.2-51,8.1-55.4,6.8c-7.2,1.1-16.8,1.7-27.7,0
			c-24.6-3.8-41.2-16.8-48.6-23.5c-3.1,1.7-9,4.3-16.7,4.7C697.1,782.5,691.8,781.1,688.6,780z"
        />
        <path
          class="st12"
          d="M680.2,603.2c1.3-0.1,1.8,11.7,8.4,50.2c7.7,45.5,11.6,68.2,17.3,81.1c7.2,16.4,25.8,45.8,81.6,67.5
			c-8,0.3-21.3-0.1-36.6-5.8c-14.4-5.3-24.5-13.1-30.3-18.3c-6.3,1.4-12.6,2.8-18.8,4.2c-3.8-11.4-8.5-26.9-12.6-45.5
			C673.6,666.5,677.3,603.4,680.2,603.2z"
        />
      </g>
      <g id="hand_left">
        <path
          class="st4"
          d="M548.1,562.6c-0.5,2.1,2.6,4.9,5.1,6.1c4.1,2,8,0.3,8.6,0c0,0,9.2-1,17.7,4c2.6,1.5,5,4.7,9.6,11.1
			c8.5,11.6,9.6,15.5,15.2,19.2c3.9,2.6,7.7,3.6,10.1,4c2.4,2.7,6.1,6.9,10.6,12.1c16.1,18.8,17.2,22.2,24.8,28.8
			c8.2,7.2,18.1,16,29.8,14.7c6.2-0.7,11.2-4.1,19.2-12.1c16.6-16.7,28.1-35.1,38.4-56.1c11.1-22.6,13.6-33.6,14.7-38.9
			c0.6-3.3,6.5-33.1-2-36.9c-3.3-1.5-7.6,1.4-14.1,6.6c-23.5,18.8-37.4,38.5-38.4,39.9c-7.4,10.7-16.7,25.5-25.8,44.5
			c-14.3-7.4-28.6-14.8-42.9-22.2c-0.9-1.9-2-4.9-2-8.6c0-6.1,3.1-8.4,5.1-15.2c2.1-7,2.4-16.6-1-18.2c-2.4-1.1-7,1.6-9.1,5.1
			c-2.2,3.6-0.9,6.8-3,8.1c-2.7,1.7-7.6-1.7-8.1-2c-5.4-3.8-4.9-8.5-9.1-16.2c-4.5-8.3-10.5-12.9-14.1-15.7
			c-5-3.8-10.6-8.1-16.2-6.6c-0.9,0.2-4.6,1.3-5.9,4.6c-0.6,1.6-0.4,3.1-0.2,4c-0.4-0.3-4-2.5-8-1c-2.8,1-5.1,3.6-5.6,6.6
			c-0.2,1.3,0,2.4,0.2,3.2c-0.5-0.2-4.8-1.3-7.8,1.3c-0.5,0.4-2.2,1.9-2.2,4c0,1.7,1.2,3.1,3.3,4.8c4.5,3.7,7.9,4.6,12.1,6.9
			c2.1,1.1,4.9,3,8.1,5.9C559.7,558.4,548.9,558.9,548.1,562.6z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M672.8,610.1c17.5-34.1,36.8-70.6,72.7-88.5c1.7-0.9,0.2-3.4-1.5-2.6c-36.3,18-56,55-73.7,89.5
					C669.3,610.3,671.9,611.8,672.8,610.1L672.8,610.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M628.7,589.3c15.4,10.3,34.6,14.6,47.8,28.1c1.4,1.4,3.5-0.7,2.1-2.1c-13.4-13.7-32.8-18.1-48.4-28.6
					C628.6,585.6,627.1,588.2,628.7,589.3L628.7,589.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M616.2,609.1c7.3,12.6,16.4,24.1,26.9,34.1c9.4,8.9,21.7,20.3,35.5,20.6c12.6,0.3,21.2-10.2,27.9-19.5
					c10-13.9,19.2-28.3,27.8-43.1c8.8-15.1,18.1-31.1,18.5-49c0-1.9-3-1.9-3,0c-0.4,19.2-11.4,36.4-21,52.4
					c-9.2,15.5-18.7,32-30.6,45.5c-5.5,6.3-12.6,11.5-21.3,10.6c-9.7-1-18.3-8-25.5-14.1c-13.1-11-24-24.3-32.7-39
					C617.8,605.9,615.2,607.4,616.2,609.1L616.2,609.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M618.9,607.2c-4-1.9-8.6-2.5-12.5-4.7c-4-2.3-6.8-6.2-9.4-10c-4.6-6.8-8.8-13.9-15.3-19.1
					c-3-2.4-6.5-4.4-10.3-5.4c-4.2-1.2-8-0.5-12.3-0.3c-2.7,0.1-8.3,0.2-8.5-3.9c-0.3-4,5.3-3.4,7.6-3.5c5.1-0.3,10,0,14.7,2
					c1.8,0.7,2.6-2.2,0.8-2.9c-5.6-2.3-11.3-2.4-17.3-2c-2.9,0.2-6.7,0.2-8.3,3.1c-1.4,2.5-0.3,5.7,1.6,7.7c3.2,3.4,8,2.7,12.2,2.3
					c15-1.5,23.9,10.5,31.3,21.8c2.6,4,5.3,8,9,11c4.5,3.6,10.1,4.1,15.1,6.6C619.1,610.6,620.7,608,618.9,607.2L618.9,607.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M630.9,587.9c-5.4-6.3-0.4-16.1,1.5-22.9c1.4-4.9,3.4-11.7,0.8-16.6c-1.5-2.9-4.8-4.7-8-3.5
					c-1.8,0.7-3.2,2.1-4.2,3.7c-1.4,2.3-1.8,8.6-4.6,9.3c-1.9,0.4-1.1,3.3,0.8,2.9c3.4-0.8,4.1-4.2,5.1-7.1c0.5-1.5,1.5-5.4,3.4-5.7
					c1.8,1,3.6,2,5.4,3c0.1,0.9,0.2,1.7,0.4,2.6c0.2,3.6-0.9,7.3-1.8,10.7c-2.4,8.1-7.1,18.3-0.8,25.8
					C630,591.5,632.1,589.3,630.9,587.9L630.9,587.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M549.7,547c-1.5-0.8-3.1-1.7-4.3-2.8c-1.7-0.9-2-2.2-0.9-3.8c-0.1-2.3,1.1-3.1,3.5-2.7
					c4.5-0.3,8.4,1.9,12.3,3.7c6.7,3.2,13.1,6.6,18.7,11.5c1.4,1.3,3.6-0.8,2.1-2.1c-4.9-4.3-10.4-7.6-16.2-10.5
					c-5.5-2.8-11.4-6.3-17.8-5.6c-3.3,0.4-7.2,1.6-7.1,5.5c0.1,4.5,4.7,7.3,8.1,9.3C549.8,550.5,551.3,547.9,549.7,547L549.7,547z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M553.6,534.9c-5.3-4,1.6-6.7,3.7-7.1c2-0.3,4,0.2,5.9,0.8c3.2,1.1,6.1,3,8.9,4.9c5.4,3.7,10.3,8,14.9,12.7
					c1.4,1.4,3.5-0.7,2.1-2.1c-7.5-7.6-18.3-18.4-29.6-19.4c-7.1-0.6-14.8,7.1-7.4,12.7C553.6,538.7,555.1,536.1,553.6,534.9
					L553.6,534.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M566.6,525.9c-3.6-4.5,4.5-6.1,6.9-5.7c1,0.2,1.9,0.5,2.9,0.8c3.2,1.1,6.1,2.9,8.7,5
					c4.7,3.7,8.6,8.4,12.2,13.2c1.2,1.5,3.8,0,2.6-1.5c-6.3-8.3-13.7-17-24.2-20c-3.5-1-8.1-1.3-10.9,1.6c-2.5,2.6-2.5,6.1-0.3,8.7
					C565.7,529.5,567.8,527.4,566.6,525.9L566.6,525.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M596.3,537.1c3.7,6.3,7,13,11.2,19c2.6,3.7,6.4,6.4,11,4.5c1.8-0.7,1-3.6-0.8-2.9c-6.1,2.4-9.3-6-11.7-10.1
					c-2.4-4-4.7-8.1-7.1-12.1C598,533.9,595.4,535.4,596.3,537.1L596.3,537.1z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M547.2,548.4c5.6,3.7,11.3,7.3,16.9,11c1.6,1.1,3.1-1.5,1.5-2.6c-5.6-3.7-11.3-7.3-16.9-11
					C547.1,544.8,545.6,547.4,547.2,548.4L547.2,548.4z"
            />
          </g>
        </g>
        <path
          class="st8"
          d="M544.2,538.5c4-1.9,10.1,3.4,13.9,6.5c18.5,15.1,19.4,9.8,30,20.7c10.8,11,10.5,17,21.3,23.6
			c5.1,3.1,9.9,4.7,13.2,5.5c1.9,2.7,4.7,6.7,8.4,11.3c13.3,16.7,20.2,25.4,31,29.4c0.9,0.3,18.2,6.5,32.6-1.9
			c11.5-6.7,11.5-17.4,24.9-52.3c16.9-44,23.7-49.5,28.1-49.1c2.6,0.2,5,2.6,6.1,4.5c2.3,3.9,0.5,8.1-1.9,17.8
			c-2.3,9.2-1.8,9.6-3.2,15.2c-3.9,14.8-10.9,23-30.7,56.5c-3.2,5.3-7.5,12.7-14.9,21.3c-6.3,7.4-10.3,11.8-16.5,13.9
			c-10.4,3.5-20.6-1.9-25.5-4.5c-6.6-3.5-11.3-7.8-18.7-16.5c-6.9-8-15.7-19.3-24.9-33.9c-2.1,0-5.4-0.4-9-1.9
			c-3.1-1.4-7.1-3.9-16.8-17.8c-6.9-9.9-6.8-11.3-10.3-13.6c-5.5-3.6-12.3-4.2-15.8-4.5c-8.1-0.8-12.4,0.9-14.5-1.9
			c-1.3-1.8-1.4-4.8,0-6.5c3.4-4.2,15,1,15.5-0.3c0.3-0.9-4.3-3.3-12.3-8.1c-9.5-5.7-12.5-7.9-12.3-10.3
			C542,540.1,543.1,539,544.2,538.5z"
        />
      </g>
      <g id="hand_right">
        <path
          class="st4"
          d="M872.5,500.4c-5.4,1.1-7.5,11.2-9.6,20.7c-2.6,12.2-2.8,22.5-2.5,30.3c0.7,21.5,5.3,42,11.1,54.1
			c3.1,6.5,5,7.3,8.6,15.7c1.2,2.8,2.1,5.2,4.5,7.1c4.6,3.5,10.6,2.2,11.1,2c6.2-1.5,9.5-6.9,11.1-9.6c4.5-7.7,4.6-15.6,3.5-29.3
			c-1.6-20.7-2.4-31-5.6-41.9c-2.8-9.7-7.5-21.9-16.2-34.4C883.3,507.3,877.7,499.4,872.5,500.4z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M872.6,503.1c5.4-0.6,9.3,6,12.1,9.9c2.5,3.5,4.8,7.2,7,10.9c4.3,7.7,7.8,15.8,10.4,24.2
					c5.2,16.6,7.4,34,7.2,51.4c-0.1,9,0,20.8-7.6,27.2c-3.5,2.9-8.8,4.7-13.1,2.6c-4.5-2.2-7.1-8.2-9.4-12.3
					c-9.2-16.6-15-35-17-53.9c-2.2-20.5-0.7-43,10.6-60.7c1-1.6-1.6-3.1-2.6-1.5c-11.6,18.2-13.2,41.3-11,62.2
					c2.2,21.6,9.3,43.1,21,61.4c4.3,6.7,10.3,10.7,18.3,7.6c8.3-3.2,12-11.5,13.1-19.8c2.8-19.6,0.1-41.3-5.2-60.3
					c-3.1-11.1-7.5-21.8-13.3-31.8c-2.8-4.8-6-9.4-9.4-13.7c-2.9-3.6-6.1-7-11-6.5C870.7,500.3,870.7,503.3,872.6,503.1L872.6,503.1
					z"
            />
          </g>
        </g>
        <path
          class="st6"
          d="M875.7,503c-2.6,12.7-3.9,27.4-2.3,43.6c1.6,15.5,5.4,26.9,10.3,41.7c3.9,11.7,9.3,25.5,16.8,40.7
			c-2.1,1.3-5.4,2.8-9,2.3c-7.3-1.1-11.6-9.6-14.5-16.2c-10.6-23.6-15.9-35.4-16.8-55.6c-1.3-27.7,6.1-58.4,12.6-58.5
			C874,501,875,502.1,875.7,503z"
        />
      </g>
      <g id="torso">
        <path
          class="st0"
          d="M735.1,520.1c-0.5,4.3-1,10.4-1.5,17.7c-0.4,5.5-1.4,21.6-1,36.9c0.9,30.2,7.5,54.2,11.6,68.7
			c3.7,13,7.6,23.7,10.6,31.3c0.3,2.9,1,7,2.5,11.6c7.2,21.6,25.8,32.3,30.3,34.9c22.5,12.6,44.9,8,51.5,6.6
			c6.6-1.5,27.9-6.4,41.9-25.3c4.6-6.1,8.7-14.1,12.6-35.4c1.8-9.9,6-35.9,3-80.3c-3.1-45.2-10.4-62.2-14.7-70.7
			c-3.8-7.7-7.8-13.7-10.6-17.7c-23.3-9.6-42.7-9.8-53.6-9.1c-18.3,1.2-32.3,6.7-46,12.1C756.2,507.6,743.8,514.6,735.1,520.1z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M733,522.8c-7.4,52.8,0.5,106.9,22.6,155.4c0.8,1.8,3.4,0.2,2.6-1.5c-21.8-47.7-29.6-101.2-22.3-153.1
					C736.1,521.7,733.2,520.9,733,522.8L733,522.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M751.5,670.8c3.6,25.1,21.7,46.8,45.5,55.5c25.4,9.3,55.9,3.8,76.2-14c10.6-9.2,17.9-21.6,19.3-35.7
					c0.2-1.9-2.8-1.9-3,0c-3.3,33.7-40.6,52.8-71.4,50.9c-31.8-1.9-59.2-25.8-63.7-57.6C754.1,668.1,751.2,668.9,751.5,670.8
					L751.5,670.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M734.8,522.8c39.7-25,89.4-42.7,135.1-23c1.8,0.8,3.3-1.8,1.5-2.6c-46.8-20.2-97.4-2.7-138.2,23
					C731.7,521.3,733.2,523.9,734.8,522.8L734.8,522.8z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M869.9,499.5c10.8,9.9,15,26.5,18.1,40.4c3.4,15.2,5.4,30.8,6.7,46.4c2.6,30.9,1.7,62.1-4.5,92.6
					c-0.4,1.9,2.5,2.7,2.9,0.8c6.6-32,7.4-64.7,4.3-97.2c-1.5-15.7-3.5-31.6-7.3-47c-3.3-13.2-7.7-28.6-18-38.1
					C870.6,496.1,868.5,498.2,869.9,499.5L869.9,499.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M769.2,687.6c12.6,11.5,28.1,18.7,45,20.8c1.9,0.2,1.9-2.8,0-3c-16.1-2-31-9-42.9-20
					C769.9,684.2,767.7,686.3,769.2,687.6L769.2,687.6z"
            />
          </g>
        </g>
        <path
          class="st13"
          d="M814.5,490.5c3.7,5.8,7.5,11.7,11.2,17.5c7.7,18.7,17.2,46,23.1,80.2c2.9,17.1,5.7,33.1,4.7,53.1
			c-0.9,18-5.5,50.6-28.7,88.3c5.1-0.1,12.5-0.7,20.9-3.4c3.5-1.1,29.3-9.8,41.8-34.3c5.2-10.2,6.1-19.3,6.9-30.3
			c4.2-59,0.3-97.1-1.9-110.2c-1.8-10.8-4.4-23.3-11.5-37.5c-3.7-7.3-7.6-12.9-10.3-16.5c-6.9-3-17.2-6.5-30-7.8
			C830.2,488.6,821.2,489.4,814.5,490.5z"
        />
        <path
          class="st6"
          d="M882.9,522.1c-3.6,0.2-5.4,21.9-6.6,36.5c-2.7,33.4-0.7,57.5-0.3,66.2c1.1,23.4-4.4,57.2-34,101.7
			c4.7-1.2,11.7-3.5,19.4-7.8c6.6-3.7,16-9,22.8-20c2.3-3.8,5.9-10.7,7.2-20.6c3.2-18.4,6.4-44.8,5.3-76.5
			C895.2,564,887.9,521.8,882.9,522.1z"
        />
      </g>
      <g id="chair_support">
        <g>
          <g>
            <path
              d="M738.2,596.6c5.6,81.5,0.9,163.2-3.8,244.6c-0.2,3.9,5.8,3.8,6,0c4.8-81.4,9.4-163.1,3.8-244.6
					C743.9,592.7,737.9,592.7,738.2,596.6L738.2,596.6z"
            />
          </g>
        </g>
        <path
          class="st14"
          d="M740.3,592.9c29.5,1.2,61.8,1.1,96.6-1.1c26.3-1.6,51-4.3,73.9-7.6c-0.5,25.4-1.1,50.7-1.6,76.1
			c-25.3,5-54.6,9.1-87.4,10.8c-29,1.5-55.5,0.7-78.8-1.1C742.1,644.3,741.2,618.6,740.3,592.9z"
        />
        <g>
          <g>
            <path
              d="M906.2,601c-7.4,76.8-14.7,153.6-22.1,230.4c-0.4,3.8,5.6,3.8,6,0c7.4-76.8,14.7-153.6,22.1-230.4
					C912.6,597.2,906.6,597.2,906.2,601L906.2,601z"
            />
          </g>
        </g>
      </g>
      <g id="head">
        <path
          class="st4"
          d="M725.8,432.8c-0.7,2,0,5.9,0.8,10.9c0.2,1.4,0.8,4.2,2.2,8.9c2.5,8.2,5.4,14.2,6.1,15.6
			c7.1,14.4,15.9,23.7,18.4,26.2c4.2,4.2,8.1,7.4,10.9,9.5c8.7-3.5,17.4-7.1,26.2-10.6c3.1-2.3,7.9-6.5,11.1-13.4
			c5.8-12.5,2.7-26.2-1.9-34.2C783.5,417.6,729.3,423,725.8,432.8z"
        />
        <g>
          <g>
            <path
              class="st3"
              d="M724.2,440.9c3,13.5,8,26.5,15.2,38.4c3.4,5.6,7.3,10.9,11.5,15.9c3.7,4.4,7.6,8.7,13.5,10.1
					c1.9,0.4,2.7-2.5,0.8-2.9c-6.2-1.4-10.4-7.1-14.3-11.9c-4-4.9-7.5-10.2-10.6-15.6c-6.2-10.9-10.6-22.6-13.2-34.8
					C726.7,438.3,723.8,439.1,724.2,440.9L724.2,440.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st3"
              d="M746.8,465.5c-0.4-8.7,10-6,13.4-1.5c4,5.2,2.9,16.4-5.9,14.9c-1.9-0.3-2.7,2.6-0.8,2.9
					c11.3,1.9,15.1-11.9,9.1-19.7c-5.2-6.8-19.4-8.3-18.8,3.3C743.9,467.4,746.9,467.4,746.8,465.5L746.8,465.5z"
            />
          </g>
        </g>
        <path
          class="st1"
          d="M724.7,438.7c-1.3-4.6-3.5-12,0.4-19.3c3.4-6.2,9.3-8.8,11.7-9.6c-0.1-1.4-0.4-9.2,5.6-15.7
			c6.1-6.5,13.9-6.8,15.3-6.8c2.5-1.9,6.1-4.3,10.9-6c1.7-0.6,9.6-3.4,18.9-2c12.8,1.9,15,9.6,29.3,15.7c4.8,2,12.4,4.5,22.9,4.8
			c-2.5,2.9-5.1,5.9-7.6,8.8c1.9,2.1,4.5,5.4,6.4,10c1,2.3,2.8,7.6,2.4,17.7c-0.4,9.8-2.8,17.7-4.8,24.5
			c-3.6,11.8-5.7,15.3-3.6,20.1c2.3,5.4,7.8,6.9,7.1,9.2c-0.8,2.5-7.8,2.8-12.7,2.2c-7.3-0.9-11.9-4.1-12.7-3
			c-1,1.3,5.9,5.9,4.8,8.9c-1.3,3.9-14.4,3.3-23.8,1.1c-3.2-0.7-8.8-2.4-15.4-6.1c-8.4-4.7-14-10.5-17.3-14.5
			c2.5-5.6,2.2-11.8-1.2-16.1c-0.3-0.4-5.3-6.5-11.3-4.8c-3.3,1-5.1,3.9-5.6,4.8C740.1,460.2,728.8,452.7,724.7,438.7z"
        />
        <path
          class="st15"
          d="M737,410.5c2.1,6.3,15.8,5.1,26,8.5c7.3,2.4,17,8.7,25.7,26.1c-2.4-7.8-13.5-40-39.9-47.2
			c-2.5-0.7-5.2-1.2-8.3-1.4c-1,1.2-3,4-3.6,7.9C736.9,404.9,736.3,408.3,737,410.5z"
        />
        <path
          class="st16"
          d="M758.3,386.7c1.8-1.4,4.4-3.2,7.7-4.6c2.6-1.1,5-1.7,6.9-2.1c5.2,4.5,13.1,10.3,23.8,14.6
			c18.1,7.3,34.3,6.4,42.6,5.3c-1.3,2.4-3.6,5.8-7.4,8.5c-13.9,9.9-34.7,1.4-44.6-2.6C780.4,403,769.4,397.5,758.3,386.7z"
        />
        <path
          class="st17"
          d="M807.5,455.5c-1.6,0.5,0.5,11.8,1.2,15.2c1.6,7.8,4.1,14.1,6.2,18.3c3.2,1,6.4,2.1,9.5,3.1
			C812.6,461.4,808.8,455.1,807.5,455.5z"
        />
        <path
          class="st8"
          d="M760.2,467.6c0.3,2.5,0.5,4.8-0.8,6.7c-1.6,2.3-4.1,1.9-6.3,5.2c-0.8,1.2-0.8,1.8-0.6,2.1
			c0.5,0.9,2.6,0.5,3.6,0.5c5.2,0.2,8.3,5.5,11.9,8.6c2.9,2.6,7.9,5.3,16.9,5.5c-4.4-2.4-9.5-5.6-14.7-9.9c-2.9-2.4-5.5-4.9-7.7-7.2
			c1-2.2,2.8-7.1,0.9-12.1c-1.4-3.8-4.6-6.5-5-6.2C757.9,461.1,759.7,463.4,760.2,467.6z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #ec615b;
}
.st1 {
  fill: #5222d0;
}
.st2 {
  fill: #e8e8e8;
}
.st3 {
  fill: #161616;
}
.st4 {
  fill: #ffffff;
}
.st5 {
  opacity: 9e-2;
  fill: #4c4c4c;
}
.st6 {
  opacity: 0.11;
  fill: #4c4c4c;
}
.st7 {
  opacity: 0.16;
  fill: #5222d0;
}
.st8 {
  opacity: 0.12;
  fill: #4c4c4c;
}
.st9 {
  fill: #4c4c4c;
}
.st10 {
  opacity: 8e-2;
  fill: #4c4c4c;
}
.st11 {
  opacity: 0.1;
  fill: #4c4c4c;
}
.st12 {
  opacity: 0.13;
  fill: #4c4c4c;
}
.st13 {
  opacity: 0.15;
  fill: #4c4c4c;
}
.st14 {
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st15 {
  opacity: 0.24;
  fill: #4c4c4c;
}
.st16 {
  opacity: 0.17;
  fill: #4c4c4c;
}
.st17 {
  opacity: 0.14;
  fill: #4c4c4c;
}
</style>
