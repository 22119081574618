<template>
  <div class="w-full max-w-screen-lg">
    <div class="card bg-gradient-to-b from-indigo-500 to-indigo-400 shadow-xl">
      <div class="card-body">
        <h2 class="card-title font-semibold text-3xl lg:text-4xl">
          Get in touch
        </h2>

        <p class="md:text-lg lg:text-xl">We'll be happy to show you more.</p>

        <div class="justify-center card-actions">
          <a
            href="mailto:STEAMplug<contact-us@steamplug.com>"
            role="button"
            class="btn"
          >
            Email us
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
